import { CircularProgress, Fade, Stack } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useIsHovered } from '../../hooks/use-is-hovered';
import { appStyled } from '../../theme';
import { AppIconRefresh } from '../AppIcons';
import { Aspect } from '../AspectRatio';
import { useVideoHasError } from './videoPlayerHooks';
import { VideoRef } from './VideoTypes';
import { random } from 'lodash';

const VideoContainer = appStyled('div')({
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    overflow: 'hidden',
});

const VideoOverlay = appStyled('div')({
    position: 'absolute',
    width: '100%',
    height: 'auto',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
});

export const VideoOverlayCentered = appStyled('div')(({ theme }) => ({
    padding: theme.spacing(0.25),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.4)',
    height: '100%',
    width: '100%',
    display: 'flex',
}));

export const VideoFlippable = appStyled('video', {
    shouldForwardProp: (prop) => prop !== 'flipped',
})<{ flipped?: boolean }>(({ flipped }) => ({
    transform: flipped ? 'rotate(180deg)' : undefined,
}));

const Video = appStyled(VideoFlippable)({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
});

export type VideoPlayerBaseProps = {
    controls: React.ReactElement<unknown, React.JSXElementConstructor<unknown>> | undefined;
    overlays?: Array<React.ReactNode>;
    flipped?: boolean;
    containerRef?: React.RefObject<HTMLDivElement>;
    hideControlsUnlessHover?: boolean;
    loading?: boolean;
    videoRef: VideoRef;
    onReload?: () => void;
} & Omit<React.ComponentProps<'video'>, 'controls'>;
export function VideoPlayerBase({
    controls,
    overlays = [],
    flipped,
    videoRef,
    loading,
    containerRef,
    hideControlsUnlessHover,
    onReload,
    ...props
}: VideoPlayerBaseProps): React.ReactElement {
    const [isHovered, hoveredProps] = useIsHovered();
    const [isErrorHovered, isErrorHoveredProps] = useIsHovered();

    if (loading) {
        overlays.push(
            <VideoOverlayCentered>
                <CircularProgress data-testid={'video-loading-spinner'} />
            </VideoOverlayCentered>,
        );
    }

    const hasError = useVideoHasError(videoRef);

    if (hasError) {
        overlays = [
            <VideoOverlayCentered
                key="error"
                {...isErrorHoveredProps}
                sx={onReload ? { cursor: 'pointer' } : /* istanbul ignore next */ undefined}
                onClick={onReload}
                data-testid={'error-overlay'}
            >
                <Stack>
                    <div>{'Error Loading Video'}</div>
                    {onReload ? (
                        <Box
                            sx={{
                                transition: 'transform 100ms ease-in',
                                transform: isErrorHovered ? 'rotate(180deg)' : null,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <AppIconRefresh />
                        </Box>
                    ) : /*istanbul ignore next*/ null}
                </Stack>
            </VideoOverlayCentered>,
        ];
    }

    const showControls = !hideControlsUnlessHover || isHovered;

    return (
        <>
            <Aspect ratio={4 / 3} ref={containerRef}>
                <VideoContainer {...hoveredProps}>
                    <Video ref={videoRef} flipped={flipped} {...props} />
                    <>
                        {overlays.map((o) => (
                            <VideoOverlay key={random(true)}>{o}</VideoOverlay>
                        ))}
                        {controls && !hasError ? (
                            <Fade in={showControls}>
                                <div>{controls}</div>
                            </Fade>
                        ) : null}
                    </>
                </VideoContainer>
            </Aspect>
        </>
    );
}
