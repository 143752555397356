import * as React from 'react';
import { DriverEvent, DriverLog, DriverLogId } from '../../../types/stein';
import { Skeleton, Tooltip } from '@mui/material';
import { Aspect } from '../../AspectRatio';
import { DriverLogVideo } from './DriverLogVideo';
import { useAppTheme, appStyled } from '../../../theme';
import { isBefore, isAfter, addSeconds, intervalToDuration, format } from 'date-fns';
import { AppIconPrimary, AppIconPast, AppIconFuture } from '../../AppIcons';
import { formatShortDuration } from '../../../utils/datetime-utils';
import { DriverEventVideoTimeline } from './DriverEventVideoTimeline';
import { useAppSelector } from '../../../hooks/hooks';
import { selectDriverLogById } from '../../../store/selectors';
import { VideoFlippable } from '../../VideoPlayer/VideoPlayerBase';

const CenteredAspect = appStyled(Aspect)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[400],
}));

const VideoSection = appStyled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    '& > div': {
        marginRight: theme.spacing(0.5),
    },
}));

const VideoTimelineWrapper = appStyled('div')(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const Overlay = appStyled('div')({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'absolute',
    zIndex: 1, // Required to stay on top of the video when it's transformed
});

const VideoThumbIconWrapper = appStyled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
    top: '6px',
    right: '6px',
    position: 'absolute',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
}));

const NowPlayingBadge = appStyled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: '12px',
    left: '8px',
    position: 'absolute',
    fontSize: '10px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
}));

function useTooltipAndIcon(
    event: DriverEvent,
    driverLog: DriverLog | undefined,
    primary: boolean,
): {
    icon: React.ReactElement | null;
    tooltip: React.ReactElement | string;
} {
    const theme = useAppTheme();
    const res = {
        icon: null,
        tooltip: 'Error loading metadata',
    };

    if (!driverLog || !driverLog.occurredAt || !driverLog.duration || !event.occurredAt) {
        return res;
    }
    const videoStart = new Date(driverLog.occurredAt);
    const videoEnd = addSeconds(new Date(driverLog.occurredAt), Math.ceil(driverLog.duration));
    const eventTime = new Date(event.occurredAt);
    const videoStartsBeforeEvent = isBefore(videoStart, eventTime);
    const videoEndsAfterEvent = isAfter(videoEnd, eventTime);
    const videoBeforeEvent = videoStartsBeforeEvent && !videoEndsAfterEvent;
    const videoAfterEvent = !videoStartsBeforeEvent && videoEndsAfterEvent;

    let duration = null;
    let tooltip = null;
    let icon = null;

    if (primary) {
        tooltip = `This is a primary event video.`;
        icon = <AppIconPrimary color={theme.colors.gold} />;
    } else if (videoBeforeEvent) {
        duration = intervalToDuration({ start: videoEnd, end: eventTime });
        tooltip = `Video ends ${formatShortDuration(duration)} before event was triggered`;
        icon = <AppIconPast color={theme.palette.success.main} style={{ fontSize: '16px' }} />;
    } else if (videoAfterEvent) {
        duration = intervalToDuration({ start: eventTime, end: videoStart });
        tooltip = `Video starts ${formatShortDuration(duration)} after event was triggered`;
        icon = <AppIconFuture color={theme.palette.warning.main} style={{ fontSize: '16px' }} />;
    } else {
        tooltip = `Video recorded the event.`;
        icon = <AppIconPrimary color={theme.colors.gold} />;
    }

    return {
        tooltip: (
            <>
                <div>{tooltip}</div>
                <br></br>
                <div>{`Start Time: ${format(videoStart, 'h:mm aaa')}`}</div>
                <div>{`End Time: ${format(videoEnd, 'h:mm aaa')}`}</div>
            </>
        ),
        icon: (
            <VideoThumbIconWrapper>
                {duration ? formatShortDuration(duration) : null} {icon}
            </VideoThumbIconWrapper>
        ),
    };
}

function VideoThumb({
    selected,
    event,
    driverLogId,
    primary = false,
}: {
    selected: boolean;
    event: DriverEvent;
    driverLogId: DriverLogId;
    primary?: boolean;
}): React.ReactElement | null {
    const driverLog = useAppSelector((s) => selectDriverLogById(s, driverLogId));

    const theme = useAppTheme();
    const { tooltip, icon } = useTooltipAndIcon(event, driverLog, primary);

    if (!driverLog) {
        return null;
    }

    return (
        <Tooltip title={tooltip}>
            <div style={{ position: 'relative', cursor: 'pointer', width: '100px', maxWidth: '100px' }}>
                <Overlay>
                    {icon}
                    {selected ? <NowPlayingBadge>{'now playing'}</NowPlayingBadge> : null}
                </Overlay>

                <VideoFlippable
                    width="100"
                    preload="metadata"
                    flipped={driverLog?.mediaRotation === 180}
                    style={{
                        borderWidth: '3px',
                        borderStyle: 'solid',
                        borderColor: selected ? theme.palette.primary.main : 'rgba(0,0,0,0)',
                    }}
                >
                    <source src={`${driverLog.url}#t=0.5`} type="video/mp4" />
                </VideoFlippable>
            </div>
        </Tooltip>
    );
}

type DriverEventVideoListProps = {
    event: DriverEvent | undefined;
    primaryVideos: DriverLog[];
    nearbyVideos: DriverLog[];
    isLoading: boolean;
};

export function DriverEventVideoList({
    event,
    primaryVideos,
    nearbyVideos,
    isLoading,
}: DriverEventVideoListProps): React.ReactElement {
    const [selectedVideoId, setSelectedVideoId] = React.useState<null | DriverLogId>(null);

    if (!event || isLoading) {
        return (
            <Skeleton variant="rectangular" width="100%" data-testid={'videolist-skeleton'}>
                <Aspect ratio={4 / 3} />
            </Skeleton>
        );
    }

    if (!primaryVideos.length && !nearbyVideos.length) {
        return <CenteredAspect ratio={4 / 3}>{'No Videos'}</CenteredAspect>;
    }

    const activeVideoId = selectedVideoId ? selectedVideoId : primaryVideos.length ? primaryVideos[0].id : null;

    return (
        <>
            {activeVideoId ? (
                <VideoTimelineWrapper>
                    <DriverLogVideo driverLogId={activeVideoId} />
                    <DriverEventVideoTimeline videoId={activeVideoId} event={event} />
                </VideoTimelineWrapper>
            ) : (
                <CenteredAspect ratio={4 / 3}>{'No Video of Event'}</CenteredAspect>
            )}

            {primaryVideos.length ? (
                <VideoSection data-testid={'primary-videos'}>
                    {primaryVideos.map((v) => (
                        <div data-testid={'video'} key={v.id} onClick={() => setSelectedVideoId(v.id)}>
                            <VideoThumb event={event} driverLogId={v.id} selected={v.id === activeVideoId} primary />
                        </div>
                    ))}
                </VideoSection>
            ) : null}
            {nearbyVideos.length ? (
                <VideoSection data-testid={'nearby-videos'}>
                    {nearbyVideos.map((v) => (
                        <div data-testid={'video'} key={v.id} onClick={() => setSelectedVideoId(v.id)}>
                            <VideoThumb event={event} driverLogId={v.id} selected={v.id === activeVideoId} />
                        </div>
                    ))}
                </VideoSection>
            ) : null}
        </>
    );
}
