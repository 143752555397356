import { Stack } from '@mui/material';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useAppSelector } from '../../../hooks/hooks';
import { useFrame } from '../../../lib/frame-react';
import { selectVehicleBySlug } from '../../../store/selectors';
import { VehicleDeviceSlug } from '../../../types/stein';
import { ToParams } from '../../../types/util-types';

import { PageLayoutColumns } from '../../PageLayouts';
import { ErrorPage } from '../ErrorPage';
import { VehicleDeviceActivityLog } from './VehicleDeviceActivityLog';
import { VehicleDeviceAuditBox } from './VehicleDeviceAuditBox';
import { VehicleDeviceCalibrationBox } from './VehicleDeviceCalibrationBox';
import { VehicleDeviceConfigurationBox } from './VehicleDeviceConfigurationBox';
import { VehicleDeviceContactBox } from './VehicleDeviceContactBox';
import { VehicleDeviceVisibility } from './VehicleDeviceVisibility';
import { VehicleDeviceMapBox } from './VehicleDeviceMapBox';
import { VehicleDeviceStatusBox } from './VehicleDeviceStatusBox';
import { VehicleDeviceLinks } from './VehicleDeviceLinks';
import { VehicleDeviceAppInstallLink } from './VehicleDeviceAppInstallLink';
import { AppIconEdit, AppIconVehicle } from '../../AppIcons';
import { urlVehicleDeviceList } from '../../../utils/internal-url-utils';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { Breadcrumbs } from '../../Breadcrumbs';
import { Permission } from '../../Authorize/PermissionBlock';
import { IconButtonSimple } from '../../material/IconButtonSimple';
import { VehicleDeviceNameEditModal } from './VehicleDeviceEditNameModal';

type VehicleParams = {
    vehicleDeviceSlug: VehicleDeviceSlug;
};

function useVehicleDeviceParams(): VehicleParams {
    const params = useParams<ToParams<VehicleParams>>();
    return {
        ...params,
        vehicleDeviceSlug: params.vehicleDeviceSlug as VehicleDeviceSlug,
    };
}

export function VehicleDeviceShow(): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const { vehicleDeviceSlug } = useVehicleDeviceParams();
    const vehicle = useAppSelector((s) => selectVehicleBySlug(s, vehicleDeviceSlug));
    const { useGetVehicleDeviceQuery } = useFrame(SteinInternalApiClientToken);
    const { isLoading, data } = useGetVehicleDeviceQuery({ idOrSlug: vehicleDeviceSlug });
    const [editModalOpen, setEditModalOpen] = React.useState(false);
    const locatedPing = data?.locatedPing;

    if (!isLoading && !vehicle) {
        return <ErrorPage type={'not-found'} content={'Vehicle Not Found'} />;
    }

    return (
        <Stack spacing={1}>
            <Breadcrumbs
                items={[
                    {
                        Icon: AppIconVehicle,
                        name: 'Vehicles',
                        url: urlVehicleDeviceList(projectSlug),
                    },
                    <Stack spacing={1} direction={'row'} key={'vehicle'}>
                        <span>{vehicle?.name || ''}</span>
                        <Permission auth={{ subject: 'VehicleDevice', action: 'manage_maintenance_attributes' }}>
                            <IconButtonSimple onClick={() => setEditModalOpen(true)}>
                                <AppIconEdit />
                            </IconButtonSimple>
                        </Permission>
                    </Stack>,
                ]}
            />
            {vehicle?.archivedAt ? (
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    sx={{ backgroundColor: (t) => t.palette.error.light, padding: 2, borderRadius: 1, color: 'white' }}
                >
                    <div>This vehicle is archived, and will not show up on the vehicles list.</div>
                </Stack>
            ) : null}
            <PageLayoutColumns
                left={
                    <>
                        <VehicleDeviceStatusBox vehicle={vehicle} />
                        <VehicleDeviceConfigurationBox vehicle={vehicle} />
                        <VehicleDeviceCalibrationBox vehicle={vehicle} />
                        <Permission auth={{ subject: 'VehicleDevice', action: 'view_activity_log' }}>
                            <VehicleDeviceActivityLog vehicle={vehicle} />
                        </Permission>
                    </>
                }
                right={
                    <>
                        <Permission auth={{ subject: 'VehicleDevice', action: 'register_app_install' }} debugOnly>
                            <VehicleDeviceAppInstallLink vehicle={vehicle} />
                        </Permission>
                        <VehicleDeviceLinks vehicle={vehicle} />
                        <VehicleDeviceContactBox vehicle={vehicle} />
                        <Permission
                            auth={{ subject: 'Audit', action: 'manage_lifecycle' }}
                            feature={'audits_tab_enabled'}
                        >
                            <VehicleDeviceAuditBox vehicle={vehicle} />
                        </Permission>
                        <VehicleDeviceMapBox vehicle={vehicle} locatedPing={locatedPing} />
                        <Permission auth={{ subject: 'VehicleDevice', action: 'archive' }}>
                            <VehicleDeviceVisibility vehicle={vehicle} />
                        </Permission>
                    </>
                }
            />
            {vehicle ? (
                <VehicleDeviceNameEditModal
                    vehicle={vehicle}
                    onClose={() => setEditModalOpen(false)}
                    open={editModalOpen}
                />
            ) : null}
        </Stack>
    );
}
