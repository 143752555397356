import * as React from 'react';

export function useShowOnChange(timeMs: number, dep?: unknown): boolean {
    const [show, setShow] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (typeof dep !== 'undefined') {
            setShow(true);
        }
        const timeout = setTimeout(() => setShow(false), timeMs);
        return () => {
            clearTimeout(timeout);
            setShow(false);
        };
    }, [timeMs, setShow, dep]);
    return show;
}
