import * as React from 'react';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { appStyled } from '../theme';

type InvisibleLinkProps = {
    children: React.ReactNode;
    to: string;
};

// istanbul ignore next
const onClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    // istanbul ignore next
    e.preventDefault();
    // istanbul ignore next
    e.stopPropagation();
};

const NoStyle = appStyled(Link)({
    textDecoration: 'inherit',
    color: 'inherit',
    cursor: 'auto',
    margin: 0,
    padding: 0,
    display: 'flex',
    '&:visited': {
        textDecoration: 'inherit',
        color: 'inherit',
        cursor: 'auto',
    },
});

export function InvisibleLink({ to, children }: InvisibleLinkProps): React.ReactElement {
    return (
        // @ts-expect-error the component property does exist on this, I don't know why it's not recognizing it
        <NoStyle component={RouterLink} to={to} onClick={onClick}>
            {children}
        </NoStyle>
    );
}
