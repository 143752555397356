// istanbul ignore file
import { Stack, Box, Link, Divider, Typography, Collapse } from '@mui/material';
import * as React from 'react';
import { SteinInternalApiClientToken } from '../../../../clients/stein-internal-api';
import { useFrame } from '../../../../lib/frame-react';
import { MultiVideoController } from '../../../../lib/multi-video/multi-video';
import { Audit, AuditTag, DateTimestampMs } from '../../../../types/stein';
import { formatLocalizedTime, sortTimeDesc, toTimestampMs } from '../../../../utils/datetime-utils';
import { AppIconRemoveItem, AppIconEdit } from '../../../AppIcons';
import { IconButtonSimple } from '../../../material/IconButtonSimple';
import { AuditTagBehaviorIcon } from './AuditTagBehaviorIcon';
import { auditTagCategoryToHumanReadable } from './AuditTagConstants';
import { AuditTagEditModal } from './AuditTagEditModal';

import { TransitionGroup } from 'react-transition-group';

type AuditTagRowProps = {
    tag: AuditTag;
    existingTags: AuditTag[];
    audit: Audit;
    playbackTime: DateTimestampMs;
    video: MultiVideoController;
};

function AuditTagRow({ tag, existingTags, audit, video }: AuditTagRowProps): React.ReactElement {
    const { useDeleteAuditTagMutation } = useFrame(SteinInternalApiClientToken);
    const [deleteAuditTag, { isLoading: isDeleting }] = useDeleteAuditTagMutation();
    const time = new Date(tag.startedAt);
    const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
    return (
        <Box key={tag.id}>
            <strong>
                <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <AuditTagBehaviorIcon behavior={tag.behavior} />
                        <div>{auditTagCategoryToHumanReadable(tag.category)}</div>
                    </Stack>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <IconButtonSimple onClick={() => setEditModalOpen(true)}>
                            <AppIconEdit />
                        </IconButtonSimple>
                        <IconButtonSimple
                            onClick={() =>
                                deleteAuditTag({
                                    auditSlug: audit.slug,
                                    auditTagSlug: tag.slug,
                                })
                            }
                            loading={isDeleting}
                        >
                            <AppIconRemoveItem />
                        </IconButtonSimple>
                    </Stack>
                </Stack>
            </strong>
            <Typography variant={'caption'}>
                <Link onClick={() => video.seekTo(toTimestampMs(time))} sx={{ cursor: 'pointer' }}>
                    {formatLocalizedTime(time)}
                </Link>
                <div>{tag.user.displayName}</div>
            </Typography>
            {tag.comment && (
                <Typography variant={'caption'}>
                    <div>{`"${tag.comment}"`}</div>
                </Typography>
            )}
            <Divider sx={{ marginTop: 1 }} />
            <AuditTagEditModal
                existingTags={existingTags}
                auditTag={tag}
                auditSlug={audit.slug}
                mode={'edit'}
                auditTagSlug={tag.slug}
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
            />
        </Box>
    );
}

type AuditTagListProps = {
    audit: Audit;
    playbackTime: DateTimestampMs;
    tags: AuditTag[];
    video: MultiVideoController;
};

export function AuditTagList({ audit, playbackTime, tags, video }: AuditTagListProps): React.ReactElement {
    const sortedTags = [...tags].sort((a, b) => sortTimeDesc(a.startedAt, b.startedAt));

    return (
        <Stack spacing={1}>
            <TransitionGroup>
                {sortedTags.map((t) => (
                    <Collapse key={t.slug} in={true}>
                        <AuditTagRow
                            key={t.slug}
                            tag={t}
                            audit={audit}
                            video={video}
                            playbackTime={playbackTime}
                            existingTags={tags}
                        />
                    </Collapse>
                ))}
            </TransitionGroup>
        </Stack>
    );
}
