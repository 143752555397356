import * as React from 'react';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import HistoryIcon from '@mui/icons-material/History';
import UpdateIcon from '@mui/icons-material/Update';

import { Icon, SvgIcon, Tooltip } from '@mui/material';
import { appStyled } from '../theme';
import { IOSUpdateNeededSvg } from './svgs/IOSUpdateNeededSvg';
import { VehicleSVG } from './svgs/VehicleSvg';

export type StringSize = `${number}em` | `${number}rem` | `${number}px` | 'inherit';

export type FontAwesomeClassName = `fa${string}`;
type MaterialComponet = React.FC<React.ComponentProps<typeof HistoryIcon>>;

export type AppIconProps = {
    color?: string;
    size?: StringSize | number;
    style?: React.CSSProperties;
    className?: string;
};

export type AppIconComponent = React.FC<AppIconProps>;

const StyledIcon = appStyled(Icon, {
    shouldForwardProp: (prop) => prop !== 'actualFontSize',
})<{ actualFontSize: StringSize | number }>(({ actualFontSize }) => ({
    boxSizing: 'content-box',
    padding: 3,
    fontSize: actualFontSize,
    width: 'inherit',
    lineHeight: '1em',
}));

const StyledSvgIcon = appStyled(SvgIcon, {
    shouldForwardProp: (prop) => prop !== 'actualFontSize',
})<{ actualFontSize: StringSize | number }>(({ actualFontSize }) => ({
    boxSizing: 'content-box',
    padding: 3,
    fontSize: actualFontSize,
    width: 'inherit',
    lineHeight: '1em',
}));

function materialIcon(Icon: MaterialComponet, label: string): AppIconComponent {
    return React.forwardRef<SVGSVGElement, AppIconProps>(function MaterialIcon({ color, size, style, ...props }, ref) {
        return (
            <Icon
                {...props}
                ref={ref}
                style={{ color: color || 'inherit', fontSize: size || 'inherit', ...style }}
                aria-label={`${label} Icon`}
            />
        );
    });
}

function faIcon(
    faClassName: FontAwesomeClassName,
    label: string,
    defaultStyle: React.CSSProperties = {},
): AppIconComponent {
    return React.forwardRef<HTMLElement, AppIconProps>(function FaIcon(
        { color, size, style, className, ...props },
        ref,
    ) {
        return (
            <StyledIcon
                {...props}
                ref={ref}
                className={`${faClassName} ${className || ''} fa-fw`}
                actualFontSize={size || 'inherit'}
                style={{ ...defaultStyle, ...style, color: color || 'inherit' }}
                aria-label={`${label} Icon`}
            />
        );
    });
}

function svgIcon(Svg: React.FC, label: string): AppIconComponent {
    return React.forwardRef<SVGSVGElement, AppIconProps>(function FaIcon(
        { color, size, style, className, ...props },
        ref,
    ) {
        return (
            <StyledSvgIcon
                {...props}
                ref={ref}
                className={className}
                actualFontSize={size || 'inherit'}
                style={{ ...style, color: color || 'inherit' }}
                aria-label={`${label} Icon`}
            >
                <Svg />
            </StyledSvgIcon>
        );
    });
}

type AppIconBaseProps = AppIconProps & {
    name: string;
    iconClass: FontAwesomeClassName;
    showTooltip?: boolean;
};

export const AppIconBase = React.forwardRef<HTMLElement, AppIconBaseProps>(function AppIconBase(
    { name, iconClass, color, style, size, className, showTooltip, ...props },
    ref,
): React.ReactElement {
    const icon = (
        <StyledIcon
            {...props}
            ref={ref}
            baseClassName={'fas'}
            className={`${iconClass} fa-fw ${className}`}
            actualFontSize={size || 'inherit'}
            style={{ ...style, color }}
            aria-label={`${name} Icon`}
        />
    );

    if (showTooltip) {
        return <Tooltip title={name}>{icon}</Tooltip>;
    }

    return icon;
});

export const AppIconUser = faIcon('fas fa-user', 'User');
export const AppIconUsers = faIcon('fad fa-users', 'Users');
export const AppIconRoles = faIcon('fad fa-user-tag', 'Roles');
export const AppIconDevice = faIcon('fad fa-mobile-alt', 'Device');
export const AppIconProject = faIcon('fad fa-sitemap', 'Project');
export const AppIconNotification = faIcon('fas fa-bell', 'Notification');
export const AppIconProjectNotification = faIcon('fad fa-bell-on', 'Notification');
export const AppIconBug = faIcon('fas fa-bug', 'Bug');
export const AppIconLogout = faIcon('fas fa-sign-out', 'Bug');
export const AppIconDashboard = faIcon('fas fa-tachometer-alt-fastest', 'Dashboard');
export const AppIconEvents = faIcon('fas fa-camera-polaroid', 'Events');
export const AppIconVehicle = faIcon('fas fa-car', 'Vehicle');
export const AppIconVehicles = faIcon('fas fa-cars', 'Vehicles');
export const AppIconSettings = faIcon('fas fa-cogs', 'Settings');
export const AppIconLogs = faIcon('fas fa-file-image', 'Logs');
export const AppIconDiagnostics = faIcon('fas fa-user-md', 'Diagnostics');
export const AppIconGeofences = faIcon('fas fa-street-view', 'Geofences');
export const AppIconFacilities = faIcon('fas fa-car-building', 'Facilities');
export const AppIconSupport = faIcon('fas fa-phone', 'Support');
export const AppIconDriverGuide = faIcon('fas fa-book-reader', 'Driver Guide');
export const AppIconDocumentation = faIcon('fas fa-books', 'Documentation');
export const AppIconResources = faIcon('fas fa-book', 'Resources');
export const AppIconRegions = faIcon('fas fa-globe-americas', 'Regions');
export const AppIconSave = faIcon('fas fa-save', 'Save');
export const AppIconSearch = faIcon('fas fa-search', 'Search');

export const AppIconBattery = faIcon('fal fa-battery-empty', 'Battery');
export const AppIconBoltOutline = faIcon('fal fa-bolt', 'Bolt Outline');
export const AppIconBolt = faIcon('fas fa-bolt', 'Bolt');
export const AppIconQuestion = faIcon('fas fa-question', 'Question');

export const AppIconFilterPreset = faIcon('fas fa-window-restore', 'Filter Preset');

export const AppIconGodMode = faIcon('fas fa-jedi', 'God Mode');
export const AppIconMDM = faIcon('fas fa-phone-laptop', 'MDM');
export const AppIconSoundEffect = faIcon('fas fa-waveform-path', 'Sound Effect');
export const AppIconAppBuild = faIcon('fab fa-app-store', 'App Build');
export const AppIconSidekiq = faIcon('fas fa-user-ninja', 'Sidekiq');
export const AppIconSandbox = faIcon('fas fa-digging', 'Sandbox');
export const AppIconInfo = faIcon('fas fa-info-circle', 'Info');

export const AppIconRecordingStarted = faIcon('fas fa-video', 'Recording Started');
export const AppIconAudioAlertSounded = faIcon('fas fa-volume-up', 'Audio Alert Sounded');

export const AppIconDownCaret = faIcon('fas caret-down', 'Caret Down');
export const AppIconUpCaret = faIcon('fas caret-up', 'Caret Up');

export const AppIconPlay = faIcon('fas fa-play', 'Play');
export const AppIconPause = faIcon('fas fa-pause', 'Pause');
export const AppIconSkipForward = faIcon('fas fa-redo', 'Skip Forward');
export const AppIconSkipBack = faIcon('fas fa-undo', 'Skip Back');
export const AppIconFullScreen = faIcon('fas fa-expand-alt', 'Full Screen');
export const AppIconMinimize = faIcon('fas fa-compress-alt', 'Minimize');
export const AppIconRotateVideo = faIcon('fad fa-retweet-alt', 'Rotate Video');
export const AppIconPictureInPicture = materialIcon(PictureInPictureIcon, 'Picture in Picture');

export const AppIconResetSelection = faIcon('fas fa-times-circle', 'Reset');
export const AppIconRemoveItem = faIcon('fas fa-times-circle', 'Remove Item');

export const AppIconTrash = faIcon('fad fa-trash', 'Trash');
export const AppIconClose = faIcon('fa fa-times-circle', 'Close');
export const AppIconSelectAll = faIcon('fas fa-plus-circle', 'Select All');
export const AppIconArrowRight = faIcon('fas fa-long-arrow-right', 'Arrow Right');

export const AppIconNew = faIcon('fas fa-plus', 'New');

export const AppIconArchive = faIcon('fas fa-archive', 'Archive');
export const AppIconZipArchive = faIcon('fas fa-file-archive', 'Archive');
export const AppIconDownload = faIcon('fas fa-download', 'Download');

export const AppIconActivityEdit = faIcon('fas fa-user-edit', 'Activity Edit');
export const AppIconEdit = faIcon('far fa-edit', 'Edit');

export const AppIconLiveUpdateOnline = faIcon('fas fa-circle', 'Live Update Online');
export const AppIconLiveUpdateOffline = faIcon('far fa-circle', 'Live Update Offline');
export const AppIconWarning = faIcon('fas fa-exclamation', 'Warning');
export const AppIconWarningCircle = faIcon('far fa-exclamation-circle', 'WarningCircle');
export const AppIconWarningTriangle = faIcon('fas fa-exclamation-triangle', 'Warning Triangle');

export const AppIconErrorNotFound = faIcon('fad fa-compass-slash', 'Error Not Found');
export const AppIconError = faIcon('fad fa-robot', 'Error');

export const AppIconPast = materialIcon(HistoryIcon, 'Past');
export const AppIconFuture = materialIcon(UpdateIcon, 'Future');
export const AppIconPrimary = faIcon('fas fa-star', 'Primary');

export const AppIconMobile = faIcon('fad fa-mobile', 'Mobile');

export const AppIconMicMute = faIcon('fas fa-microphone', 'Microphone Mute');
export const AppIconMicUnmute = faIcon('fas fa-microphone-slash', 'Microphone Unmute');

export const AppIconPhone = faIcon('fad fa-microphone-alt', 'Phone');
export const AppIconPhoneDisconnect = faIcon('fad fa-phone-slash', 'Phone Disconnect');
export const AppIconPhoneMute = faIcon('fad fa-microphone-alt', 'Phone Mute');
export const AppIconPhoneUnmute = faIcon('fad fa-microphone-alt-slash', 'Phone Unmute');

export const AppIconMuted = faIcon('fad fa-volume-mute', 'Muted');
export const AppIconUnmuted = faIcon('fad fa-volume', 'Unmuted');

export const AppIconVolumeUp = faIcon('fad fa-volume-up', 'Volume Up');
export const AppIconVolumeDown = faIcon('fad fa-volume-down', 'Volume Down');

export const AppIconVideoCall = faIcon('fad fa-camera-home', 'Video Call');
export const AppIconCameraSnapshot = faIcon('fad fa-camera', 'Snapshot');
export const AppIconScreenshot = faIcon('fad fa-mobile', 'Screenshot');

export const AppIconSend = faIcon('fas fa-paper-plane', 'Send');

export const AppIconAppUpdate = faIcon('fad fa-arrow-alt-square-down', 'App Update');

export const AppIconExpand = faIcon('fas fa-caret-down', 'Expand');
export const AppIconCollapse = faIcon('fas fa-caret-up', 'Collapse');

export const AppIconSlack = faIcon('fab fa-slack', 'Slack');
export const AppIconSMS = faIcon('fas fa-sms', 'SMS');
export const AppIconEmail = faIcon('fas fa-envelope', 'Email');
export const AppIconVoiceAlert = faIcon('fas fa-microphone-alt', 'Email');
export const AppIconGenericNotification = faIcon('fas fa-bell', 'Generic Notification');

export const AppIconWifiConnected = faIcon('far fa-wifi', 'Wifi Connected');
export const AppIconWifiDisconnected = faIcon('far fa-wifi-slash', 'Wifi Disconnected');
export const AppIconAlertsMuted = faIcon('fas fa-volume-mute', 'Alerts Muted');

export const AppIconMap = faIcon('fad fa-map-marked-alt', 'Map');

export const AppIconIOSUpdateNeeded = svgIcon(IOSUpdateNeededSvg, 'iOS Update Needed');

export const AppIconCopy = faIcon('fad fa-copy', 'Copy');

export const AppIconSimulate = faIcon('fad fa-eye', 'Simulate');
export const AppIconAudit = faIcon('fas fa-search-location', 'Audit');

export const AppIconAuditTagPositive = faIcon('fas fa-thumbs-up', 'Audit Tag Positive');
export const AppIconAuditTagNegative = faIcon('fas fa-thumbs-down', 'Audit Tag Negative');

export const AppIconChevronLeft = faIcon('fas fa-chevron-left', 'Chevron Left');
export const AppIconChevronLeftDouble = faIcon('fas fa-chevron-double-left', 'Chevron Left Double');
export const AppIconChevronRight = faIcon('fas fa-chevron-right', 'Chevron Right');
export const AppIconChevronRightDouble = faIcon('fas fa-chevron-double-right', 'Chevron Right Double');
export const AppIconChevronUp = faIcon('fas fa-chevron-up', 'Chevron Up');
export const AppIconChevronDown = faIcon('fas fa-chevron-down', 'Chevron Down');

export const AppIconCustomAnnouncement = faIcon('fas fa-comment-alt-edit', 'Custom Announcement');
export const AppIconAnnouncement = faIcon('fas fa-comment-alt', 'Announcement');
export const AppIconComment = faIcon('fas fa-comment-alt-edit', 'Comment');

export const AppIconCalendar = faIcon('fad fa-calendar', 'Calendar');

export const AppIconChat = faIcon('fad fa-comments', 'Chat');

export const AppIconMenu = faIcon('fas fa-bars', 'Menu');

export const AppIconLink = faIcon('fas fa-link', 'Link');

export const AppIconAnalytics = faIcon('fas fa-analytics', 'Analytics');
export const AppIconRefresh = faIcon('far fa-sync', 'Refresh');

export const AppIconVehicleTopDown = svgIcon(VehicleSVG, 'Vehicle Top Down');

export const AppIconGlobe = faIcon('fas fa-globe-americas', 'Globe');

export const AppIconLiveVideo = faIcon('fas fa-grid-2', 'Live Video');
export const AppIconCameraDisabled = faIcon('fas fa-camera-home', 'Camera Disabled');

export const AppIconBrackets = faIcon('fal fa-brackets', 'Brackets');
