import * as React from 'react';
import { FilterBar } from '../../FilterBar';

import { FilterMultiselect } from '../../FilterBar/FilterMultiselect';
import { FilterDateRangePicker } from '../../FilterBar/FilterDateRangePicker';

import { useFrame } from '../../../lib/frame-react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useAppNavigate, useAppSelector } from '../../../hooks/hooks';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';

import { selectFilterConfig, selectRegionsByProjectSlug, selectVehiclesByProjectSlug } from '../../../store/selectors';
import { DateStrUTCTimestamp, DriverEvent, RegionId, VehicleDeviceSlug } from '../../../types/stein';
import { DriverEventsRequest } from '../../../types/stein-internal-api';

import { DataGrid } from '../../DataGrid';

import { urlDriverEventShow } from '../../../utils/internal-url-utils';
import { FilterPresets } from '../../FilterBar/FilterPresets';
import { FilterReset } from '../../FilterBar/FilterReset';
import { filterOptionsFromRegions, filterOptionsFromVehicles } from '../../../utils/filter-utils';
import { useDriverEventColumns } from './useDriverEventColumns';
import { GridRowClassNameParams, GridSortModel } from '@mui/x-data-grid-pro';
import { isEqual } from 'lodash';
import { resolveFilterValueNum, shouldIncludeFilter, useFilters } from '../../../hooks/use-filters';
import { FilterPageNumber } from '../../FilterBar/FilterPage';

//TODO remove ignore Cannot test inside DataGrid, wait for: https://github.com/mui-org/material-ui-x/issues/1151
/* istanbul ignore file */

const SORT_MODEL: GridSortModel = [{ field: 'processedAt', sort: 'desc' }];

function useRequest(defaults?: Record<string, string[] | undefined>): DriverEventsRequest {
    const projectSlug = useActiveProjectSlug();
    const { filters } = useFilters();
    const {
        regions,
        vehicles,
        eventNames,
        analysis,
        infractions,
        dateRange,
        page: pageStr,
        pageSize: pageSizeStr,
    } = filters;

    const page = resolveFilterValueNum(pageStr);
    const pageSize = resolveFilterValueNum(pageSizeStr);

    return React.useMemo(() => {
        const req: DriverEventsRequest = {
            projectSlug,
        };

        if (page) {
            req.page = page;
        }
        if (pageSize) {
            req.perPage = pageSize;
        }

        if (shouldIncludeFilter(regions)) {
            req.regions = regions.map((v) => parseInt(v)) as RegionId[];
        }
        if (shouldIncludeFilter(vehicles)) {
            req.vehicleDeviceSlugs = vehicles as VehicleDeviceSlug[];
        }
        const eventNamesToSearch = eventNames || defaults?.eventNames;
        if (shouldIncludeFilter(eventNamesToSearch)) {
            req.eventNames = eventNamesToSearch;
        }
        if (shouldIncludeFilter(analysis)) {
            req.analysis = analysis;
        }
        if (shouldIncludeFilter(infractions)) {
            req.flagAttributes = infractions;
        }
        if (shouldIncludeFilter(dateRange)) {
            if (dateRange[0]) {
                req.startTime = dateRange[0] as DateStrUTCTimestamp;
            }

            if (dateRange[1]) {
                req.endTime = dateRange[1] as DateStrUTCTimestamp;
            }
        }
        return req;
    }, [projectSlug, regions, vehicles, eventNames, analysis, infractions, dateRange, page, pageSize]);
}

export function DriverEventList(): React.ReactElement {
    const { useGetDriverEventsQuery } = useFrame(SteinInternalApiClientToken);
    const { options: filterOptions, activePreset } = useAppSelector((s) => selectFilterConfig(s, 'driverEvents'));
    const defaultEventNames = activePreset?.filters['eventNames'];

    const req = useRequest({ eventNames: defaultEventNames });

    const projectSlug = useActiveProjectSlug();
    const naviagate = useAppNavigate();
    const columns = useDriverEventColumns();

    const { data, isLoading, isFetching, isUninitialized } = useGetDriverEventsQuery(
        {
            ...req,
        },
        { refetchOnMountOrArgChange: true },
    );
    const hasNextPage = data?.metadata.hasNextPage ? data.metadata.hasNextPage : false;
    const unseenDriverEvents = useAppSelector((s) => s.driverEvents.entities);

    const { filters } = useFilters();
    const driverEvents = (data?.driverEvents || []).map((de) => ({ ...de, isNew: Boolean(unseenDriverEvents[de.id]) }));

    const regions = useAppSelector((s) => selectRegionsByProjectSlug(s, projectSlug));
    const vehicles = useAppSelector((s) => selectVehiclesByProjectSlug(s, projectSlug));
    const hasFilters = !isEqual(filters, activePreset.filters);

    function onRowChosen(d: DriverEvent): void {
        naviagate(urlDriverEventShow(projectSlug, d));
    }

    return (
        <>
            <span data-testid={'page-driver-event-index'} />
            <FilterBar
                icons={
                    <>
                        <FilterPresets filterContextName={'driverEvents'} />
                        {hasFilters ? <FilterReset filterContextName={'driverEvents'} /> : null}
                    </>
                }
            >
                <FilterMultiselect filterName={'regions'} options={filterOptionsFromRegions(regions)} />
                <FilterMultiselect filterName={'vehicles'} options={filterOptionsFromVehicles(vehicles)} filterable />
                <FilterMultiselect
                    filterName={'eventNames'}
                    label={'Event Types'}
                    options={filterOptions.eventNames}
                    defaultValue={defaultEventNames}
                />
                <FilterMultiselect filterName={'analysis'} options={filterOptions.analysis} />
                <FilterMultiselect filterName={'infractions'} options={filterOptions.infractions} />
                <FilterDateRangePicker />
                <FilterPageNumber hasNextPage={hasNextPage} />
            </FilterBar>
            <DataGrid
                onRowChosen={onRowChosen}
                isLoading={isLoading || isFetching}
                isUninitialized={isUninitialized}
                columns={columns}
                sortModel={SORT_MODEL}
                rows={driverEvents}
                getRowClassName={getRowClassName}
                localeText={{
                    noRowsLabel: 'No Matching Events',
                }}
            />
        </>
    );
}

function getRowClassName({ row }: GridRowClassNameParams): string {
    return row.isNew ? 'success' : '';
}
