import { createPlugin, createToken } from '../../lib/frame';
import { AppReduxStoreToken } from '../../store';
import { LoggerToken } from '../../lib/frame-tokens';
import { createIntercomEmitter, TwilioAudioDevice, IntercomService, TwilioVideoConnect } from './intercom-types';
import { SteinInternalApiToken } from '../../clients/stein-internal-api';
import { createStartIntercomFn } from './internal/create-start-intercom-fn';

export const IntercomServiceToken = createToken<IntercomService>('IntercomServiceToken');

export const TwilioVideoConnectToken = createToken<TwilioVideoConnect>('TwilioVideoConnectToken');
export const TwilioAudioDeviceToken = createToken<TwilioAudioDevice>('TwilioAudioDeviceToken');

export const IntercomServicePlugin = createPlugin<IntercomService>(({ resolve }) => {
    const stein = resolve(SteinInternalApiToken);
    const store = resolve(AppReduxStoreToken);
    const log = resolve(LoggerToken);
    const emitter = createIntercomEmitter();
    const connect = resolve(TwilioVideoConnectToken);
    const AudioDevice = resolve(TwilioAudioDeviceToken);

    const deps = {
        stein,
        store,
        log,
        emitter,
        connect,
        AudioDevice,
    };

    return {
        startVoiceCall: createStartIntercomFn(deps, false),
        startVideoCall: createStartIntercomFn(deps, true),
        emitter,
    };
});
