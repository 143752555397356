import * as React from 'react';

//@ts-expect-error this errors but it doesn't matter
const defaultCtx: React.RefObject<HTMLVideoElement> = null;
const ctx = React.createContext<React.RefObject<HTMLVideoElement>>(defaultCtx);

export function VideoRefProvider({
    children,
    videoRef,
}: {
    children: React.ReactNode;
    videoRef: React.RefObject<HTMLVideoElement>;
}): React.ReactElement {
    return <ctx.Provider value={videoRef}>{children}</ctx.Provider>;
}

export function useVideoRef(): React.RefObject<HTMLVideoElement> {
    return React.useContext(ctx);
}
