// istanbul ignore file
import EventEmitter from '@foxify/events';
import {
    addSeconds,
    addMilliseconds,
    compareAsc,
    differenceInSeconds,
    isBefore,
    isSameSecond,
    isWithinInterval,
} from 'date-fns';
import { isEqual } from 'lodash';
import { VideoMultiSource } from '../../components/VideoPlayerMulti/videoPlayerMultiTypes';
import { DateStrISO8601, DateTimestampMs } from '../../types/stein';
import { toISO8601, toTimestampMs } from '../../utils/datetime-utils';
import { TimeoutId } from '../../types/app';

export type MultiVideoState = {
    playbackState: 'paused' | 'playing';
    // The current playback time in world time
    playbackTime: DateTimestampMs | undefined;
    currentSource: VideoMultiSource | undefined;
    startTime: DateTimestampMs | undefined;
    endTime: DateTimestampMs | undefined;
    sources: VideoMultiSource[];
};

type MultiVideoEvents = {
    videoElementChanged: (e: HTMLVideoElement | null) => void;
    stateChanged: (t: MultiVideoState) => void;
    playbackSkipped: (seconds: number) => void;
    playbackReset: () => void;
};

function getPlaybackTime(source: VideoMultiSource | undefined, videoTimeSeconds: number): DateTimestampMs | undefined {
    return source ? toTimestampMs(addMilliseconds(source.startTime, videoTimeSeconds * 1000)) : undefined;
}

export class MultiVideoController extends EventEmitter<MultiVideoEvents> {
    private srcs: VideoMultiSource[];
    private videoElement: HTMLVideoElement;
    private playNoVideoTimeout: TimeoutId | undefined;
    private videoInterval: TimeoutId | undefined;

    private state: MultiVideoState;

    constructor() {
        super();
        this.srcs = [];
        this.state = {
            playbackState: 'paused',
            playbackTime: undefined,
            currentSource: undefined,
            startTime: undefined,
            endTime: undefined,
            sources: [],
        };
    }

    public async play(): Promise<void> {
        return this.videoElement.play().catch(() => {
            this.setPartialState({ playbackState: 'playing' });
            this.onPlayNoVideo();
        });
    }

    public pause(): void {
        this.setPartialState({ playbackState: 'paused' });
        clearTimeout(this.playNoVideoTimeout);
        this.videoElement.pause();
    }

    public get playbackTime(): DateTimestampMs | undefined {
        console.log('Get Playback Time', this.state.playbackTime);
        return this.state.playbackTime;
    }

    public get currentTime(): DateStrISO8601 | undefined {
        if (this.state.playbackTime) {
            return toISO8601(new Date(this.state.playbackTime));
        } else if (this.state.startTime) {
            return toISO8601(new Date(this.state.startTime));
        }

        return undefined;
    }

    public seekTo = (playbackTime: DateTimestampMs): void => {
        const newSource = this.getSourceAtPlaybackTime(playbackTime);
        console.log('playbackTime', playbackTime);
        this.setPartialState({
            playbackTime,
        });

        if (newSource) {
            const seekTime = differenceInSeconds(playbackTime, newSource.startTime);
            this.setSource(newSource, seekTime);
        } else {
            this.onPlayNoVideo();
        }
    };

    public setSrcs = (newSrcs: VideoMultiSource[]): void => {
        if (!newSrcs.length) {
            this.videoElement ? (this.videoElement.src = '') : null;
            return this.setPartialState({
                sources: [],
                startTime: undefined,
                endTime: undefined,
                currentSource: undefined,
            });
        }
        const sorted = newSrcs.sort((a, b) => compareAsc(a.startTime, b.startTime));
        this.srcs = sorted;
        const firstSource = this.srcs[0] || undefined;
        const lastSource = this.srcs[this.srcs.length - 1] || undefined;

        if (firstSource && lastSource) {
            const startTime = firstSource.startTime;
            const endTime = toTimestampMs(addMilliseconds(lastSource.startTime, lastSource.durationSeconds * 1000));
            this.setPartialState({
                sources: sorted,
                startTime,
                endTime,
                currentSource: this.getNextSource(startTime),
            });
            console.log('setSrcs#seekTo');
            this.seekTo(startTime);
        }
    };

    private setPartialState = (state: Partial<MultiVideoState>): void => {
        const newState = { ...this.state, ...state };

        if (!isEqual(this.state, newState)) {
            this.state = newState;
            this.emit('stateChanged', this.state);
        }
    };

    private setSource = (source: VideoMultiSource, seekTime = 0): void => {
        if (this.videoElement) {
            if (!this.videoElement.src.includes(source.url)) {
                const playing = !this.videoElement.paused;
                const playbackRate = this.videoElement.playbackRate;
                this.videoElement.src = source.url;
                this.videoElement.playbackRate = playbackRate;
                if (playing) {
                    this.videoElement.play();
                }
            }
            if (this.videoElement.currentTime !== seekTime) {
                this.videoElement.currentTime = seekTime;
            }
            this.setPartialState({
                currentSource: source,
                playbackTime: getPlaybackTime(source, this.videoElement.currentTime),
            });
        }
    };

    getSourceAtPlaybackTime = (time: DateTimestampMs | undefined): VideoMultiSource | undefined => {
        if (!this.srcs.length) {
            return undefined;
        }

        const currentTime = time ? addMilliseconds(time, 1) : this.srcs[0].startTime;
        return this.srcs.find((f) => {
            const start = f.startTime;
            // Move the end up one millisecond so getting a clip at the end of
            const end = toTimestampMs(addSeconds(f.startTime, f.durationSeconds));
            const found =
                isWithinInterval(currentTime, {
                    start,
                    end,
                }) && !isSameSecond(currentTime, end);

            return found;
        });
    };

    getNextSource = (currentTime: DateTimestampMs | undefined): VideoMultiSource | undefined => {
        if (!currentTime || !this.srcs.length) {
            return undefined;
        }

        const src = this.getSourceAtPlaybackTime(currentTime);
        if (src) {
            return src;
        }

        return this.srcs.find((f) => {
            return isBefore(currentTime, f.startTime);
        });
    };

    private onPlayNoVideo = (): void => {
        const MOCK_PLAY_STEP_MS = 300 as DateTimestampMs;

        const newPlayBackTime = ((this.playbackTime || 0) + MOCK_PLAY_STEP_MS) as DateTimestampMs;
        const foundSource = this.getSourceAtPlaybackTime(newPlayBackTime);
        if (foundSource) {
            clearTimeout(this.playNoVideoTimeout);
            this.setSource(foundSource);
            this.videoElement.play();
        } else {
            this.videoElement ? (this.videoElement.src = '') : null;
            this.setPartialState({
                playbackTime: newPlayBackTime,
                currentSource: undefined,
            });
            if (this.state.playbackState === 'playing') {
                this.playNoVideoTimeout = setTimeout(() => {
                    this.onPlayNoVideo();
                }, MOCK_PLAY_STEP_MS);
            }
        }
    };

    private onSourceEnded = (): void => {
        if (this.state.currentSource) {
            const { startTime, durationSeconds } = this.state.currentSource;
            const currentSourceEndTime = toTimestampMs(addSeconds(startTime, durationSeconds + 1));
            const newSource = this.getSourceAtPlaybackTime(currentSourceEndTime);
            if (newSource) {
                this.setSource(newSource);
                this.play();
            } else {
                this.onPlayNoVideo();
                this.play();
            }
        }
    };

    private onSourcePlay = (): void => {
        this.setPartialState({ playbackState: 'playing' });
    };

    private onSourcePause = (): void => {
        this.setPartialState({ playbackState: 'paused' });
    };

    setVideoElement = (videoElement: HTMLVideoElement): void => {
        if (this.videoElement && this.videoElement != videoElement) {
            this.videoElement.removeEventListener('play', this.onSourcePlay);
            this.videoElement.removeEventListener('pause', this.onSourcePause);
            // this.videoElement.removeEventListener('abort', this.onSourcePause);
            this.videoElement.removeEventListener('ended', this.onSourceEnded);
            clearInterval(this.videoInterval);
        }

        this.videoElement = videoElement;
        this.videoElement.src = '';

        this.videoElement.addEventListener('play', this.onSourcePlay);
        this.videoElement.addEventListener('pause', this.onSourcePause);
        // this.videoElement.addEventListener('abort', this.onSourcePause);
        this.videoElement.addEventListener('ended', this.onSourceEnded);

        this.videoInterval = setInterval(() => {
            if (this.state.currentSource && !this.videoElement.paused) {
                this.setPartialState({
                    playbackTime: getPlaybackTime(this.state.currentSource, this.videoElement.currentTime),
                });
            }
        }, 100);

        const staringSource = this.getNextSource(this.state.playbackTime);

        if (staringSource) {
            this.setSource(staringSource);
        }

        this.emit('videoElementChanged', this.videoElement);
    };
}
