import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toCamelCase, toSnakeCase } from '../lib/case-transforms';
import { createToken, createPlugin } from '../lib/frame';
import { EnvToken, FetchToken } from '../lib/frame-tokens';

import * as Stein from '../types/stein';
import * as Api from '../types/stein-internal-api';
import { SnakeCasedPropertiesDeep } from 'type-fest';
import { makeQueryString } from '../utils/query-string';
import { driverLogsSlice } from '../store/slices/driverLogsSlice';
import { notificationSlice } from '../store/slices/notificationSlice';
import { vehiclesSlice } from '../store/slices/vehiclesSlice';
import { userSlice } from '../store/slices/userSlice';
import { configItemsSlice } from '../store/slices/configItemsSlice';
import { ConfigItem, ConfigItemWeb } from '../types/stein.config_items';
import { projectsSlice } from '../store/slices/projectsSlice';
import { notificationConfigsSlice } from '../store/slices/notificationConfigsSlice';
import { abilitiesSlice } from '../store/slices/abilitiesSlice';
import { setRailsContext } from '../store/actions';
import { processRailsContext } from '../utils/process_rails_context';
import { geofencesSlice } from '../store/slices/geofencesSlice';
import { regionsSlice } from '../store/slices/regionsSlice';

export type SteinAPI = ReturnType<typeof SteinInternalApiPlugin>;
export type SteinClient = Omit<
    SteinAPI,
    | 'reducerPath'
    | 'reducer'
    | 'middleware'
    | 'enhanceEndpoints'
    | 'injectEndpoints'
    | 'enchanceEndpoints'
    | 'utils'
    | 'internalActions'
    | 'usePrefetch'
    | 'baseUrl'
>;

export const SteinInternalApiToken = createToken<SteinAPI>('SteinInternalApiToken');
// The same Token, but with a reduced functionality
export const SteinInternalApiClientToken = createToken<SteinClient>('SteinInternalApiClientToken');

function makeSearch(obj: Record<string, unknown>): string {
    return makeQueryString(obj);
}

export const SteinInternalApiPlugin = createPlugin(({ resolve }) => {
    const env = resolve(EnvToken);

    // In a test environment, we dont have a DOM, so there is no concept of relative URLs.
    const baseUrl = env.NODE_ENV === 'test' ? 'http://localhost:3000/api/internal/' : '/api/internal/';

    const api = createApi({
        reducerPath: 'steinInternal',
        tagTypes: [
            'DriverEvent',
            'Audit',
            'Vehicle',
            'AppInstall',
            'Member',
            'VideoUploadRequest',
            'VehicleConfigItems',
            'ProjectConfigItems',
            'Geofences',
            'ActivityLog',
        ],
        baseQuery: fetchBaseQuery({
            baseUrl,
            fetchFn: resolve(FetchToken),
        }),
        endpoints: (build) => ({
            getRailsContext: build.query<Api.GetRailsContextResponse, Api.GetRailsContextRequest>({
                query: () => ({ url: `context.json` }),
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data?.context) {
                            const processedData = processRailsContext(data.context);
                            dispatch(setRailsContext(processedData));
                        }
                    } catch (e) {
                        /* istanbul ignore next */
                        console.error('error loading context', e);
                    }
                },
            }),
            getDriverEvents: build.query<Api.DriverEventsResponse, Api.DriverEventsRequest>({
                query: (p) => ({ url: `driver_events.json?${makeSearch(p)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.DriverEventsResponse>) =>
                    toCamelCase(res) as Api.DriverEventsResponse,
                providesTags: ['DriverEvent'],
            }),
            getDriverEvent: build.query<Api.DriverEventResponse, Api.DriverEventRequest>({
                query: (p) => ({ url: `driver_events/${p.id}.json` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.DriverEventResponse>) =>
                    toCamelCase(res) as Api.DriverEventResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            if (data.vehicleDevice) {
                                dispatch(vehiclesSlice.actions.setVehicles([data.vehicleDevice]));
                            }
                            if (data.primaryVideos) {
                                dispatch(driverLogsSlice.actions.driverLogsRecieved(data.primaryVideos));
                            }
                            if (data.nearbyVideos) {
                                dispatch(driverLogsSlice.actions.driverLogsRecieved(data.nearbyVideos));
                            }
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            updateDriverEvent: build.mutation<Api.DriverEventResponse, Api.UpdateDriverEventRequest>({
                query: ({ id, ...patch }) => ({
                    url: `driver_events/${id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({
                        driverEvent: patch,
                    }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.DriverEventResponse>) =>
                    toCamelCase(res) as Api.DriverEventResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    // istanbul ignore next
                    try {
                        // istanbul ignore next
                        const { data } = await queryFulfilled;
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(
                                api.util.updateQueryData('getDriverEvent', { id: arg.id }, (draft) => {
                                    draft.driverEvent = data.driverEvent;
                                }),
                            );
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            getVehicleDevices: build.query<Api.VehicleDevicesResponse, Api.VehicleDevicesRequest>({
                query: (p) => ({ url: `vehicle_devices.json?${makeSearch(p)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.VehicleDevicesResponse>) =>
                    toCamelCase(res) as Api.VehicleDevicesResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(vehiclesSlice.actions.setVehicles(data.vehicleDevices));
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            getVehicleDevice: build.query<Api.VehicleDeviceResponse, Api.VehicleDeviceRequest>({
                query: (p) => ({ url: `vehicle_devices/${p.idOrSlug}.json` }),
                providesTags: (res) => (res ? [{ type: 'Vehicle', id: res.vehicleDevice?.id }] : []),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.VehicleDeviceResponse>) =>
                    toCamelCase(res) as Api.VehicleDeviceResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();

                        /* istanbul ignore next */
                        if (data?.vehicleDevice) {
                            dispatch(vehiclesSlice.actions.setVehicles([data.vehicleDevice]));
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            getVehicleDevicePing: build.query<Api.VehicleDevicePingResponse, Api.VehicleDevicePingRequest>({
                query: (p) => ({ url: `vehicle_devices/${p.idOrSlug}/ping.json?${makeSearch(p)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.VehicleDevicePingResponse>) =>
                    toCamelCase(res) as Api.VehicleDevicePingResponse,
            }),
            updateVehicleDevice: build.mutation<Api.VehicleDeviceResponse, Api.UpdateVehicleDeviceRequest>({
                query: ({ id, ...patch }) => ({
                    url: `vehicle_devices/${id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({
                        vehicleDevice: patch,
                    }),
                }),
                //invalidatesTags: (res, err, req) => (res ? [{ type: 'Vehicle', id: res.vehicleDevice.id }] : []),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.VehicleDeviceResponse>) =>
                    toCamelCase(res) as Api.VehicleDeviceResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(vehiclesSlice.actions.setVehicles([data.vehicleDevice]));
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            getAppInstalls: build.query<Api.AppInstallsResponse, Api.AppInstallsRequest>({
                query: (p) => ({ url: `app_installs.json?${makeSearch(p)}` }),
                providesTags: ['AppInstall'],
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.AppInstallsResponse>) =>
                    toCamelCase(res) as Api.AppInstallsResponse,
            }),
            updateAppInstall: build.mutation<Api.AppInstallResponse, Api.UpdateAppInstallRequest>({
                query: ({ id, ...patch }) => ({
                    url: `app_installs/${id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({
                        appInstall: patch,
                    }),
                }),
                invalidatesTags: ['AppInstall'],
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.AppInstallResponse>) =>
                    toCamelCase(res) as Api.AppInstallResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(vehiclesSlice.actions.setVehicles([data.vehicleDevice]));
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            createPush: build.mutation<Api.CreatePushResponse, Api.CreatePushRequest>({
                query: (req) => ({
                    url: `pushes`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.CreatePushResponse>) =>
                    toCamelCase(res) as Api.CreatePushResponse,
            }),
            createPushMulti: build.mutation<Api.CreatePushMultiResponse, Api.CreatePushMultiRequest>({
                query: (req) => ({
                    url: `pushes`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.CreatePushMultiResponse>) =>
                    toCamelCase(res) as Api.CreatePushMultiResponse,
            }),
            getPushes: build.query<Api.GetPushesResponse, Api.GetPushesRequest>({
                query: (p) => ({ url: `pushes?${makeSearch(p)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GetPushesResponse>) =>
                    toCamelCase(res) as Api.GetPushesResponse,
            }),
            restartMdmDevice: build.mutation<Api.MdmDeviceRestartResponse, Api.MdmDeviceRestartRequest>({
                query: (req) => ({
                    url: `/mdm_devices/${req.remoteMdmDeviceId}/restart`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.MdmDeviceRestartResponse>) =>
                    toCamelCase(res) as Api.MdmDeviceRestartResponse,
            }),
            restartMdmDeviceHack: build.mutation<Api.MdmDeviceRestartResponse, Api.VehicleDeviceRequest>({
                query: (req) => ({
                    url: `/vehicle_devices/${req.idOrSlug}/force_restart`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.MdmDeviceRestartResponse>) =>
                    toCamelCase(res) as Api.MdmDeviceRestartResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: 'Sent device restart request',
                                kind: 'success',
                            }),
                        );
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: 'Unable to send device restart request',
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            geosearch: build.query<Api.GeosearchRes, Api.GeosearchReq>({
                query: (p) => ({ url: `geosearch?${makeSearch(p)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GeosearchRes>) =>
                    toCamelCase(res) as Api.GeosearchRes,
            }),
            getAudits: build.query<Api.AuditsReponse, Api.AuditsRequest>({
                query: (p) => ({ url: `audits.json?camera=0&${makeSearch(p)}` }),
                providesTags: ['Audit'],
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.AuditsReponse>) =>
                    toCamelCase(res) as Api.AuditsReponse,
            }),
            getAudit: build.query<Api.AuditRes, Api.AuditReq>({
                query: (p) => ({ url: `audits/${p.auditSlug}.json` }),
                providesTags: ['Audit'],
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.AuditRes>) => toCamelCase(res) as Api.AuditRes,
            }),
            startAudit: build.mutation<Api.AuditResponse, Api.StartAuditRequest>({
                query: (req) => ({
                    url: `audits.json`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                invalidatesTags: ['Audit'],
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.AuditResponse>) =>
                    toCamelCase(res) as Api.AuditResponse,
            }),
            updateAudit: build.mutation<Api.AuditResponse, Api.UpdateAuditRequest>({
                query: (req) => ({
                    url: `audits/${req.auditSlug}.json`,
                    method: 'PUT',
                    body: toSnakeCase(req.updates),
                }),
                invalidatesTags: ['Audit'],
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.AuditResponse>) =>
                    toCamelCase(res) as Api.AuditResponse,
            }),
            updateAuditMetadata: build.mutation<Api.UpdateAuditMetadataResponse, Api.UpdateAuditMetadataRequest>({
                query: /* istanbul ignore next */ (req) => /* istanbul ignore next */ ({
                    url: `audits/${req.auditSlug}/metadata.json`,
                    method: 'PUT',
                    body: toSnakeCase({
                        audit: {
                            customMetadata: req.customMetadata,
                        },
                    }),
                }),
                transformResponse: /* istanbul ignore next */ (
                    res: SnakeCasedPropertiesDeep<Api.UpdateAuditMetadataResponse>,
                ) => /* istanbul ignore next */ toCamelCase(res) as Api.UpdateAuditMetadataResponse,
                /* istanbul ignore next */
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    // istanbul ignore next
                    try {
                        // istanbul ignore next
                        const { data } = await queryFulfilled;
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(
                                api.util.updateQueryData('getAudit', { auditSlug: arg.auditSlug }, (draft) => {
                                    draft.audit = {
                                        ...draft.audit,
                                        ...data.audit,
                                    };
                                }),
                            );
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            createAuditTag: build.mutation<Api.CreateAuditTagRes, Api.CreateAuditTagReq>({
                query: /* istanbul ignore next */ (req) => ({
                    url: `audit_tags.json`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: /* istanbul ignore next */ (res: SnakeCasedPropertiesDeep<Api.CreateAuditTagRes>) =>
                    /* istanbul ignore next */ toCamelCase(res) as Api.CreateAuditTagRes,
                /* istanbul ignore next */
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    /* istanbul ignore next */
                    try {
                        /* istanbul ignore next */
                        const { data } = await queryFulfilled;
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(
                                api.util.updateQueryData('getAuditTags', { auditSlug: arg.auditSlug }, (draft) => {
                                    draft.auditTags.push(data.auditTag);
                                }),
                            );
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            updateAuditTag: build.mutation<Api.CreateAuditTagRes, Api.UpdateAuditTagReq>({
                query: /* istanbul ignore next */ (req) => ({
                    url: `audit_tags/${req.auditTagSlug}.json`,
                    method: 'PATCH',
                    body: toSnakeCase(req),
                }),
                transformResponse: /* istanbul ignore next */ (res: SnakeCasedPropertiesDeep<Api.CreateAuditTagRes>) =>
                    /* istanbul ignore next */ toCamelCase(res) as Api.CreateAuditTagRes,
                /* istanbul ignore next */
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    /* istanbul ignore next */
                    try {
                        /* istanbul ignore next */
                        const { data } = await queryFulfilled;
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(
                                api.util.updateQueryData('getAuditTags', { auditSlug: arg.auditSlug }, (draft) => {
                                    draft.auditTags = draft.auditTags.map((t) => {
                                        if (t.slug === arg.auditTagSlug) {
                                            return data.auditTag;
                                        }
                                        return t;
                                    });
                                }),
                            );
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            deleteAuditTag: build.mutation<Api.CreateAuditTagRes, Api.DeleteAuditTagRes>({
                query: /* istanbul ignore next */ (req) => ({
                    url: `audit_tag/${req.auditTagSlug}.json`,
                    method: 'DELETE',
                }),
                transformResponse: /* istanbul ignore next */ (res: SnakeCasedPropertiesDeep<Api.CreateAuditTagRes>) =>
                    /* istanbul ignore next */ toCamelCase(res) as Api.CreateAuditTagRes,
                /* istanbul ignore next */
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    /* istanbul ignore next */
                    try {
                        /* istanbul ignore next */
                        const { data } = await queryFulfilled;
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(
                                api.util.updateQueryData('getAuditTags', { auditSlug: arg.auditSlug }, (draft) => {
                                    draft.auditTags = draft.auditTags.filter((t) => t.slug !== arg.auditTagSlug);
                                }),
                            );
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            getAuditTags: build.query<Api.GetAuditTagsRes, Api.GetAuditTagsReq>({
                query: /* istanbul ignore next */ (req) => ({
                    url: `audit_tags.json?${makeQueryString(req)}`,
                }),
                transformResponse: /* istanbul ignore next */ (res: SnakeCasedPropertiesDeep<Api.GetAuditTagsRes>) =>
                    toCamelCase(res) as Api.GetAuditTagsRes,
            }),
            startArchive: build.query<Api.ArchiveResponse, Api.ArchiveRequest>({
                query: (p) => ({ url: `archive/${p.type}/${p.id}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.ArchiveResponse>) =>
                    toCamelCase(res) as Api.ArchiveResponse,
            }),
            getArchiveProgress: build.query<Api.ArchiveStatusResponse, Api.ArchiveStatusRequest>({
                query: (p) => ({ url: `archive/progress/${p.id}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.ArchiveStatusResponse>) => toCamelCase(res),
            }),
            undiscardAndReprocess: build.mutation<Api.UndiscardAndReprocessRes, Api.UndiscardAndReprocessReq>({
                query: /* istanbul ignore next */ (p) => ({
                    url: `driver_events/${p.driverEventId}/undiscard_and_reprocess.json`,
                    method: 'POST',
                    body: {},
                }),
                transformResponse: /* istanbul ignore next */ (
                    res: SnakeCasedPropertiesDeep<Api.UndiscardAndReprocessRes>,
                ) => toCamelCase(res),
            }),
            getActivityLog: build.query<Api.ActivityLogResponse, Api.ActivityLogRequest>({
                query: (p) => ({ url: `record_activity/${p.type}/${p.id}.json` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.ActivityLogResponse>) =>
                    toCamelCase(res) as Api.ActivityLogResponse,
                providesTags: ['ActivityLog'],
            }),
            addActivityComment: build.mutation<Record<string, string>, Api.AddActivityLogCommentRequest>({
                query: (post) => ({
                    url: `record_activity/comment.json`,
                    method: 'POST',
                    body: toSnakeCase(post),
                }),
                invalidatesTags: ['ActivityLog'],
            }),
            getTimeline: build.query<Api.GetTimelineRes, Api.GetTimelineReq>({
                query: (p) => ({ url: `driver_logs/timeline.json?${makeSearch(p)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GetTimelineRes>) =>
                    toCamelCase(res) as Api.GetTimelineRes,
            }),
            updateDriverLog: build.mutation<
                Api.DriverLogResponse,
                Partial<Stein.DriverLog> & Pick<Stein.DriverLog, 'id'>
            >({
                query: ({ id, ...patch }) => ({
                    url: `driver_logs/${id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({
                        driverLog: patch,
                    }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.DriverLogResponse>) =>
                    toCamelCase(res) as Api.DriverLogResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(driverLogsSlice.actions.driverLogsUpdated([data]));
                        }
                    } catch (e) {
                        //noop
                    }
                },
            }),
            startVideoCall: build.mutation<Api.StartVideoCallResponse, Api.StartVideoCallRequest>({
                query: (req) => ({
                    url: `video_calls.json`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.StartVideoCallResponse>) =>
                    toCamelCase(res) as Api.StartVideoCallResponse,
            }),
            updateUserProfile: build.mutation<Api.UpdateUserProfileResponse, Api.UpdateUserProfileRequest>({
                query: (req) => ({
                    url: `/profile`,
                    method: 'PATCH',
                    body: toSnakeCase({ user: req }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.UpdateUserProfileResponse>) =>
                    toCamelCase(res) as Api.UpdateUserProfileResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(userSlice.actions.updateUser(data));
                        }
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: 'Unable to update config items',
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            setGlobalConfigItems: build.mutation<
                Api.SetConfigItemsResponse<ConfigItemWeb>,
                Api.SetConfigItemsRequest<ConfigItemWeb>
            >({
                query: (req) => ({
                    url: `/configuration_items/global.json`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.SetConfigItemsResponse<ConfigItemWeb>>) =>
                    toCamelCase(res) as Api.SetConfigItemsResponse<ConfigItemWeb>,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(configItemsSlice.actions.setItems(data.configurationItems));
                        }
                    } catch (e) {
                        /* istanbul ignore next */
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: 'Unable to update global config item',
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            getProjectConfigItems: build.query<Api.GetProjectConfigItemsRes, Api.GetProjectConfigItemsReq>({
                query: (p) => ({ url: `configuration_items/project.json?${makeSearch(p)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GetProjectConfigItemsRes>) =>
                    toCamelCase(res) as Api.GetProjectConfigItemsRes,
                providesTags: ['ProjectConfigItems'],
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    await queryFulfilled;
                    const { data } = getCacheEntry();
                    /* istanbul ignore next */
                    if (data) {
                        dispatch(configItemsSlice.actions.setItems(data.configurationItems));
                    }
                },
            }),
            setUserConfigItems: build.mutation<
                Api.SetConfigItemsResponse<ConfigItemWeb>,
                Api.SetConfigItemsRequest<ConfigItemWeb>
            >({
                query: (req) => ({
                    url: `/configuration_items/user`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.SetConfigItemsResponse<ConfigItemWeb>>) =>
                    toCamelCase(res) as Api.SetConfigItemsResponse<ConfigItemWeb>,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(configItemsSlice.actions.setItems(data.configurationItems));
                        }
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: 'Unable to update user config item',
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            setProjectConfigItems: build.mutation<
                Api.SetConfigItemsResponse<ConfigItem>,
                Api.SetProjectConfigItemsRequest<ConfigItem>
            >({
                query: (req) => ({
                    url: `/configuration_items/project?project_id=${req.projectId}`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                invalidatesTags: ['ProjectConfigItems'],
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.SetProjectConfigItemsRequest<ConfigItemWeb>>) =>
                    toCamelCase(res) as Api.SetConfigItemsResponse<ConfigItemWeb>,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(configItemsSlice.actions.setItems(data.configurationItems));
                        }
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: 'Unable to update project config item',
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            deleteConfigItem: build.mutation<Api.GetConfigItemsResponse, Api.DeleteConfigItemRequest>({
                query: (req) => /* istanbul ignore next */ ({
                    url: `configuration_items/${req.configurationItemId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['VehicleConfigItems', 'ProjectConfigItems'],
            }),
            setVehicleConfigItem: build.mutation<Api.GetConfigItemsResponse, Api.SetVehicleConfigItemRequest>({
                query: (req) =>
                    /* istanbul ignore next */
                    ({
                        url: `configuration_items/vehicle_device.json`,
                        method: 'POST',
                        body: toSnakeCase(req),
                    }),
                invalidatesTags: ['VehicleConfigItems'],
            }),
            getVehicleConfigItems: build.query<Api.GetConfigItemsResponse, Api.GetVehicleConfigItemsRequest>({
                query: (p) => ({ url: `configuration_items/vehicle_device.json?${makeSearch(p)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GetConfigItemsResponse>) =>
                    toCamelCase(res) as Api.GetConfigItemsResponse,
                providesTags: ['VehicleConfigItems'],
            }),
            createOrUpdateNotifConfig: build.mutation<
                Api.CreateOrUpdateNotificaitonConfigRes,
                Api.CreateOrUpdateNotificaitonConfigReq
            >({
                query: (req) => ({
                    url: `/notification_configurations/toggle`,
                    method: 'PATCH',
                    body: toSnakeCase({
                        notificationConfiguration: req,
                    }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.CreateOrUpdateNotificaitonConfigRes>) =>
                    toCamelCase(res) as Api.CreateOrUpdateNotificaitonConfigRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(
                                notificationConfigsSlice.actions.setNotificationConfig(data.notificationConfiguration),
                            );
                        }
                    } catch (e) {
                        //pass
                    }
                },
            }),
            updateProject: build.mutation<Api.UpdateProjectResponse, Api.UpdateProjectRequest>({
                query: (req) => ({
                    url: `/projects/${req.id}`,
                    method: 'PATCH',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.UpdateProjectResponse>) =>
                    toCamelCase(res) as Api.UpdateProjectResponse,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(projectsSlice.actions.setProject(data.project));
                        }
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: 'Unable to update project.',
                                content: e.error?.data?.base || /* istanbul ignore next */ [],
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            getProjectSCIM: build.query<Api.ProjectSCIMRes, Api.ProjectSCIMReq>({
                query: (req) => ({
                    url: `/projects/${req.projectId}/scim`,
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.ProjectSCIMRes>) =>
                    toCamelCase(res) as Api.ProjectSCIMRes,
            }),
            regenerateProjectSCIM: build.mutation<Api.ProjectSCIMRes, Api.ProjectSCIMReq>({
                query: (req) => ({
                    url: `/projects/${req.projectId}/scim`,
                    method: 'POST',
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.ProjectSCIMRes>) =>
                    toCamelCase(res) as Api.ProjectSCIMRes,
            }),
            updateCustomRole: build.mutation<Api.UpdateCustomRoleRes, Api.UpdateCustomRoleReq>({
                query: ({ id, ...req }) => ({
                    url: `/custom_roles/${id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({ custom_role: req }, { ignoreKeys: ['abilities'] }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.UpdateCustomRoleRes>) =>
                    toCamelCase(res, { ignoreKeys: ['abilities'] }) as Api.UpdateCustomRoleRes,
                async onQueryStarted(args, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled;
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(
                                api.util.updateQueryData(
                                    'getCustomMemberships',
                                    { projectSlug: args.projectSlug },
                                    (draft) => {
                                        draft.customRoles = draft.customRoles.map((cr) =>
                                            cr.id !== args.id ? cr : { ...cr, ...data.customRole },
                                        );
                                    },
                                ),
                            );
                        }

                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: `Role updated`,
                                kind: 'success',
                            }),
                        );
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: `Error updating role`,
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            roleSimulationStart: build.mutation<Api.CustomRoleSimulationRes, Api.CustomRoleSimulationReq>({
                query: ({ customRoleId }) => ({
                    url: `/custom_role_simulation/${customRoleId}.json`,
                    method: 'POST',
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.CustomRoleSimulationRes>) =>
                    toCamelCase(res, { ignoreKeys: ['abilities'] }) as Api.CustomRoleSimulationRes,
                async onQueryStarted(args, { dispatch, queryFulfilled }) {
                    try {
                        const { data } = await queryFulfilled;
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(abilitiesSlice.actions.setSimulatedRole(data.customRole));
                        }
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: `Unable to simulate role.`,
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            roleSimulationEnd: build.mutation<unknown, void>({
                query: () => ({
                    url: `/custom_role_simulation.json`,
                    method: 'DELETE',
                }),
                async onQueryStarted(args, { dispatch, queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        dispatch(abilitiesSlice.actions.setSimulatedRole(null));
                    } catch (e) {}
                },
            }),
            getCustomMemberships: build.query<Api.GetCustomMemebershipsRes, Api.GetCustomMembershipsReq>({
                query: (p) => ({ url: `custom_memberships.json?${makeSearch(p)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GetCustomMemebershipsRes>) =>
                    toCamelCase(res, { ignoreKeys: ['abilities'] }) as Api.GetCustomMemebershipsRes,
            }),
            updateCustomMembership: build.mutation<Api.UpdateCustomMembershipRes, Api.UpdateCustomMembershipReq>({
                query: ({ id, ...req }) => ({
                    url: `/custom_memberships/${id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({ custom_membership: req }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.UpdateCustomMembershipRes>) =>
                    toCamelCase(res) as Api.UpdateCustomMembershipRes,
                async onQueryStarted(args, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('getCustomMemberships', { projectSlug: args.projectSlug }, (draft) => {
                            draft.customMemberships = draft.customMemberships.map((cm) =>
                                cm.id !== args.id
                                    ? /* istanbul ignore next */ cm
                                    : { ...cm, customRoleId: args.customRoleId },
                            );
                        }),
                    );

                    dispatch(
                        notificationSlice.actions.createNotification({
                            type: 'generic',
                            title: `Membership updated`,
                            kind: 'success',
                        }),
                    );
                },
            }),
            removeCustomMembership: build.mutation<Api.RemoveMemberRes, Api.RemoveMemberReq>({
                query: (req) => ({
                    url: `/custom_memberships/${req.membershipId}.json`,
                    method: 'DELETE',
                    body: toSnakeCase({ notificationConfiguration: req }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.RemoveMemberRes>) =>
                    toCamelCase(res) as Api.RemoveMemberRes,
                async onQueryStarted(args, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('getCustomMemberships', { projectSlug: args.projectSlug }, (draft) => {
                            draft.customMemberships = draft.customMemberships.filter(
                                (cm) => cm.id !== args.membershipId,
                            );
                        }),
                    );
                },
            }),
            createInvite: build.mutation<Api.CreateInviteRes, Api.CreateInviteReq>({
                query: ({ projectSlug, ...req }) => ({
                    url: `/invites.json?project_slug=${projectSlug}`,
                    method: 'POST',
                    body: toSnakeCase({ invite: req }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.CreateInviteRes>) =>
                    toCamelCase(res) as Api.CreateInviteRes,
                async onQueryStarted(args, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(
                                api.util.updateQueryData(
                                    'getCustomMemberships',
                                    { projectSlug: args.projectSlug },
                                    (draft) => {
                                        draft.invites = draft.invites.concat(data.invite);
                                    },
                                ),
                            );

                            dispatch(
                                notificationSlice.actions.createNotification({
                                    type: 'generic',
                                    title: `Invitation sent to ${args.email}`,
                                    kind: 'success',
                                }),
                            );
                        }
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: `Unable to invite ${args.email}`,
                                content: e.error?.data?.email || undefined,
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            updateInvite: build.mutation<Api.UpdateInviteRes, Api.UpdateInviteReq>({
                query: ({ id, ...req }) => ({
                    url: `/invites/${id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({ invite: req }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.UpdateInviteRes>) =>
                    toCamelCase(res) as Api.UpdateInviteRes,
                async onQueryStarted(args, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('getCustomMemberships', { projectSlug: args.projectSlug }, (draft) => {
                            draft.invites = draft.invites.map((i) =>
                                i.id !== args.id
                                    ? /* istanbul ignore next */ i
                                    : { ...i, customRoleId: args.customRoleId },
                            );
                        }),
                    );
                },
            }),
            cancelInvite: build.mutation<Api.CancelInviteRes, Api.CancelInviteReq>({
                query: (req) => ({
                    url: `/invites/${req.inviteId}.json`,
                    method: 'DELETE',
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.CancelInviteRes>) =>
                    toCamelCase(res) as Api.CancelInviteRes,
                async onQueryStarted(args, { dispatch, queryFulfilled }) {
                    await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('getCustomMemberships', { projectSlug: args.projectSlug }, (draft) => {
                            draft.invites = draft.invites.filter((cm) => cm.id !== args.inviteId);
                        }),
                    );
                },
            }),
            sendTestNotification: build.mutation<Api.SendTestNotificationRes, Api.SendTestNotificationReq>({
                query: (req) => ({
                    url: `/notification_configurations/test.json`,
                    method: 'POST',
                    body: toSnakeCase({ notificationConfiguration: req }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.SendTestNotificationRes>) =>
                    toCamelCase(res) as Api.SendTestNotificationRes,
            }),
            deleteNotificationConfig: build.mutation<Api.DeleteNotificationConfigRes, Api.DeleteNotificationConfigReq>({
                query: (req) => ({
                    url: `/notification_configurations/${req.notificationConfigId}.json`,
                    method: 'DELETE',
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.DeleteNotificationConfigRes>) =>
                    toCamelCase(res) as Api.DeleteNotificationConfigRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        dispatch(notificationConfigsSlice.actions.removeNotificationConfig(arg.notificationConfigId));
                    } catch (e) {}
                },
            }),
            updateNotificationConfig: build.mutation<Api.UpdateNotificationConfigRes, Api.UpdateNotificationConfigReq>({
                query: (req) => ({
                    url: `/notification_configurations/${req.id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({ notificationConfiguration: req }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.UpdateNotificationConfigRes>) =>
                    toCamelCase(res) as Api.UpdateNotificationConfigRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        // istanbul ignore next
                        if (data) {
                            dispatch(notificationConfigsSlice.actions.setNotificationConfig(data));
                        }
                    } catch (e) {}
                },
            }),
            createCustomNotificationConfig: build.mutation<
                Api.CreateCustomNotificaitonConfigRes,
                Api.CreateCustomNotificaitonConfigReq
            >({
                query: (req) => ({
                    url: `/notification_configurations.json`,
                    method: 'POST',
                    body: toSnakeCase({
                        notificationConfiguration: {
                            ...req,
                            category: 'any_neglect',
                            analysis: 'any',
                            medium: 'email',
                            rateNumerator: 5,
                            rateDenominator: 1,
                            rateDenominatorUnit: 'hours',
                        },
                    }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.CreateCustomNotificaitonConfigRes>) =>
                    toCamelCase(res) as Api.CreateCustomNotificaitonConfigRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        // istanbul ignore next
                        if (data) {
                            dispatch(notificationConfigsSlice.actions.setNotificationConfig(data));
                        }
                    } catch (e) {}
                },
            }),
            getVideoUploadRequests: build.query<Api.GetVideoUploadResponseRes, Api.GetVideoUploadRequestReq>({
                query: (p) => ({ url: `video_upload_requests.json?${makeSearch(p)}` }),
                providesTags: (res, err, req) => [{ type: 'VideoUploadRequest', id: req.vehicleDeviceSlug }],
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GetVideoUploadResponseRes>) =>
                    toCamelCase(res) as Api.GetVideoUploadResponseRes,
            }),
            createVideoUploadRequest: build.mutation<Api.CreateVideoUploadResponseRes, Api.CreateVideoUploadRequestReq>(
                {
                    query: (req) => ({
                        url: `/video_upload_requests.json`,
                        method: 'POST',
                        body: toSnakeCase(req),
                    }),
                    invalidatesTags: (res, err, arg) => [
                        { type: 'VideoUploadRequest', id: arg.videoUploadRequest.vehicleDeviceSlug },
                    ],
                    transformResponse: (res: SnakeCasedPropertiesDeep<Api.CreateVideoUploadResponseRes>) =>
                        toCamelCase(res) as Api.CreateVideoUploadResponseRes,
                },
            ),
            createAppInstallRegistration: build.mutation<
                Api.AppInstallRegistrationResponse,
                Api.CreateAppInstallRegistrationRequest
            >({
                query: (req) => ({
                    url: `/app_install_registrations.json`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.AppInstallRegistrationResponse>) =>
                    toCamelCase(res) as Api.AppInstallRegistrationResponse,
            }),
            getAppInstallRegistration: build.query<
                Api.AppInstallRegistrationResponse,
                Api.GetAppInstallRegistrationRequest
            >({
                query: ({ id, ...req }) => ({ url: `app_install_registrations/${id}.json?${makeSearch(req)}` }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.AppInstallRegistrationResponse>) =>
                    toCamelCase(res) as Api.AppInstallRegistrationResponse,
            }),
            updateGeofence: build.mutation<Api.GeofenceRes, Api.UpdateGeofencesReq>({
                query: ({ id, ...patch }) => ({
                    url: `geofences/${id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({
                        geofence: patch,
                    }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GeofenceRes>) =>
                    toCamelCase(res) as Api.GeofenceRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(geofencesSlice.actions.setGeofences([data.geofence]));
                        }
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: 'Unable to update facility',
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            createGeofence: build.mutation<Api.GeofenceRes, Api.CreateGeofencesReq>({
                query: (req) => ({
                    url: `geofences.json`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GeofenceRes>) =>
                    toCamelCase(res) as Api.GeofenceRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(geofencesSlice.actions.setGeofences([data.geofence]));
                        }
                    } catch (e) {}
                },
            }),
            deleteGeofence: build.mutation<Api.GeofenceRes, Api.DeleteGeofencesReq>({
                query: (geofenceId) => ({
                    url: `geofences/${geofenceId}.json`,
                    method: 'DELETE',
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.GeofenceRes>) =>
                    toCamelCase(res) as Api.GeofenceRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        dispatch(geofencesSlice.actions.delete(arg));
                    } catch (e) {}
                },
            }),
            createRegion: build.mutation<Api.RegionRes, Api.CreateRegionsReq>({
                query: (req) => ({
                    url: `regions.json`,
                    method: 'POST',
                    body: toSnakeCase(req),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.RegionRes>) => toCamelCase(res) as Api.RegionRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(regionsSlice.actions.setMany([data.region]));
                        }
                    } catch (e) {}
                },
            }),
            updateRegion: build.mutation<Api.RegionRes, Api.UpdateRegionsReq>({
                query: ({ id, ...patch }) => ({
                    url: `regions/${id}.json`,
                    method: 'PATCH',
                    body: toSnakeCase({
                        region: patch,
                    }),
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.RegionRes>) => toCamelCase(res) as Api.RegionRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled, getCacheEntry }) {
                    try {
                        await queryFulfilled;
                        const { data } = getCacheEntry();
                        /* istanbul ignore next */
                        if (data) {
                            dispatch(regionsSlice.actions.setMany([data.region]));
                        }
                    } catch (e) {
                        dispatch(
                            notificationSlice.actions.createNotification({
                                type: 'generic',
                                title: 'Unable to update Region',
                                kind: 'error',
                            }),
                        );
                    }
                },
            }),
            deleteRegion: build.mutation<Api.RegionRes, Api.DeleteRegionsReq>({
                query: (id) => ({
                    url: `regions/${id}.json`,
                    method: 'DELETE',
                }),
                transformResponse: (res: SnakeCasedPropertiesDeep<Api.RegionRes>) => toCamelCase(res) as Api.RegionRes,
                async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                    try {
                        await queryFulfilled;
                        dispatch(regionsSlice.actions.delete(arg));
                    } catch (e) {}
                },
            }),
        }),
    });

    return {
        ...api,
        baseUrl,
    };
});
