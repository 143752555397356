// istanbul ignore file

import { MultiVideoController } from '../../../lib/multi-video/multi-video';
import { DateTimestampMs } from '../../../types/stein';
import { useHotkey } from '../../HotkeyProvider';

export function useVideoMultiHotkeys(controller: MultiVideoController): void {
    useHotkey(
        'right',
        () => controller.playbackTime && controller.seekTo((controller.playbackTime + 10000) as DateTimestampMs),
        {
            description: 'Step video forward 10 seconds',
        },
        [controller],
    );
    useHotkey(
        'shift + right',
        () => controller.playbackTime && controller.seekTo((controller.playbackTime + 1000) as DateTimestampMs),
        {
            description: 'Step video forward 1 second',
        },
        [controller],
    );

    useHotkey(
        'left',
        () => controller.playbackTime && controller.seekTo((controller.playbackTime - 10000) as DateTimestampMs),
        {
            description: 'Step video backward 10 seconds',
        },
        [controller],
    );
    useHotkey(
        'shift + left',
        () => controller.playbackTime && controller.seekTo((controller.playbackTime - 1000) as DateTimestampMs),
        {
            description: 'Step video back 1 second',
        },
        [controller],
    );
}
