// istanbul ignore file
import * as React from 'react';
import { FeatureGroup, MapContainer, Marker } from 'react-leaflet';
import { MapTiles } from '../../../Map/MapTiles';
import { useForceRefresh } from '../../../../hooks/use-force-refresh';
import * as L from 'leaflet';
import 'leaflet-routing-machine';

import { defineDefault } from '../../../../utils/undefined-utils';
import { LocationEvent } from '../../../../types/stein-internal-api';
import * as ReactDOMServer from 'react-dom/server';
import { AppIconVehicleTopDown } from '../../../AppIcons';
import { MultiVideoController } from '../../../../lib/multi-video/multi-video';
import { useMultiVideoState } from '../../../VideoPlayerMulti/VideoPlayerMulti';
import { addSeconds, differenceInSeconds, isBefore } from 'date-fns';
import { parseISO8601, toISO8601 } from '../../../../utils/datetime-utils';
import { computeBearing } from '../../../../utils/latlng-utils';
import { DriverEventId } from '../../../../types/stein';
import { AutoCenterGroup } from '../../../Map/AutoCenter';

const Routing = L.Routing;

function useSnappedLocations(locations: LocationEvent[]): LocationEvent[] {
    const [snappedLocations, setSnappedLocations] = React.useState<LocationEvent[]>([]);

    React.useEffect(() => {
        if (locations.length) {
            const routingControl = Routing.control({
                waypointMode: 'snap',
                showAlternatives: false,
                // hint: false,
            });

            routingControl.getRouter().route(
                locations.map((loc) => new Routing.Waypoint(new L.LatLng(loc.latitude, loc.longitude), '', {})),
                (err: L.Routing.IError | undefined, res: L.Routing.IRoute[]) => {
                    if (err) {
                        console.error('Route error', err);
                    } else {
                        // @ts-expect-error res is unknown
                        const waypointIndicies: number[] = res[0].waypointIndices;
                        // @ts-expect-error res is unknown
                        const snappedCoordinates: L.LatLng[] = res[0].coordinates;

                        const snappedLocations: LocationEvent[] = [];

                        let prev: LocationEvent | undefined;

                        locations.forEach((loc, idx) => {
                            if (prev) {
                                const durationS = differenceInSeconds(
                                    parseISO8601(loc.occurredAt),
                                    parseISO8601(prev.occurredAt),
                                );

                                const startIdx = waypointIndicies[idx - 1];
                                const endIdx = waypointIndicies[idx];
                                const numSteps = endIdx - startIdx;

                                const secondsPerStep = durationS / (endIdx - startIdx);

                                for (let i = 0; i < numSteps; i++) {
                                    const coord = snappedCoordinates[startIdx + i];
                                    snappedLocations.push({
                                        ...prev,
                                        id: `${prev.id}-${i}` as unknown as DriverEventId,
                                        latitude: coord.lat,
                                        longitude: coord.lng,
                                        occurredAt: toISO8601(
                                            addSeconds(parseISO8601(prev.occurredAt), secondsPerStep * i),
                                        ),
                                    });
                                }
                            }
                            prev = loc;
                        });

                        console.log('setting snapped locations');
                        setSnappedLocations(snappedLocations);
                    }
                },
            );
        }
    }, [locations]);

    return snappedLocations.length ? snappedLocations : locations;
}

export function VehicleLocationHistory({
    locations,
    controller,
}: {
    locations: LocationEvent[];
    controller: MultiVideoController;
}): React.ReactElement {
    const snappedLocations = useSnappedLocations(locations);
    const refresh = useForceRefresh();
    const groupRef = React.useRef<L.FeatureGroup<unknown>>(null);
    const { playbackTime } = useMultiVideoState(controller);

    let bearing;
    let currentLocation;
    if (playbackTime) {
        let start, end;
        let startTime, endTime;
        for (const l of snappedLocations) {
            if (l.occurredAt) {
                if (isBefore(parseISO8601(l.occurredAt), new Date(playbackTime))) {
                    start = l;
                    startTime = parseISO8601(l.occurredAt);
                } else {
                    end = l;
                    endTime = parseISO8601(l.occurredAt);
                    break;
                }
            }
        }
        if (start && end && startTime && endTime) {
            const percent =
                differenceInSeconds(new Date(playbackTime), startTime) / differenceInSeconds(endTime, startTime);
            currentLocation = {
                latitude: start.latitude + (end.latitude - start.latitude) * percent,
                longitude: start.longitude + (end.longitude - start.longitude) * percent,
            };
            bearing = computeBearing(start.latitude, start.longitude, end.latitude, end.longitude);
        }
    }

    return (
        <>
            <MapContainer
                zoom={15}
                center={[33.4942, 111.9261]}
                style={{
                    height: '300px',
                    width: '300px',
                }}
                // drawControl={true}
                whenReady={refresh}
            >
                <AutoCenterGroup groupRef={groupRef} maxZoom={15} enable />
                <MapTiles />
                <FeatureGroup ref={groupRef} eventHandlers={{ layeradd: refresh, layerremove: refresh }}>
                    {currentLocation ? (
                        <Marker
                            position={[
                                defineDefault(currentLocation.latitude, 0),
                                defineDefault(currentLocation.longitude, 0),
                            ]}
                            icon={L.divIcon({
                                className: 'leaflet-invisible-marker',
                                html: ReactDOMServer.renderToStaticMarkup(
                                    <div
                                        style={{
                                            color: 'black',
                                            display: 'flex',
                                            height: '15px',
                                            width: '15px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            paddingRight: '5px',
                                            paddingLeft: '3px',
                                            transform: `translate(-50%, -50%)`,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                transform: `rotate(${bearing}deg)`,
                                                height: '100%',
                                                width: '100%',
                                                scale: '300%',
                                            }}
                                        >
                                            <AppIconVehicleTopDown />
                                        </div>
                                    </div>,
                                ),
                            })}
                        />
                    ) : null}
                </FeatureGroup>
            </MapContainer>
        </>
    );
}
