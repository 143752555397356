import * as React from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';

import { AppIconFilterPreset } from '../AppIcons';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useAppTheme } from '../../theme';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { FilterContextName } from '../../store/slices/filterConfigSlice';
import { setActivePreset } from '../../store/actions';
import { useFilters } from '../../hooks/use-filters';

type FilterPresetsProps = {
    filterContextName: FilterContextName;
};

export function FilterPresets({ filterContextName }: FilterPresetsProps): React.ReactElement | null {
    const theme = useAppTheme();
    const { updateFilters } = useFilters();
    const { presets, activePresetIndex } = useAppSelector((s) => s.filterConfig[filterContextName]);
    const dispatch = useAppDispatch();

    if (!presets.length) {
        return null;
    }

    return (
        <PopupState variant="popover">
            {(popupState) => (
                <div>
                    <IconButton
                        {...bindTrigger(popupState)}
                        style={{ color: activePresetIndex ? theme.palette.primary.main : theme.palette.common.white }}
                        size={'small'}
                    >
                        <AppIconFilterPreset />
                    </IconButton>

                    <Menu
                        {...bindMenu(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div style={{ marginLeft: theme.spacing(2) }}>
                            <Typography variant="caption" display="block" gutterBottom>
                                {'Filter presets'}
                            </Typography>
                        </div>
                        {presets.map((p, i) => (
                            <MenuItem
                                selected={i === activePresetIndex}
                                key={p.name}
                                onClick={() => {
                                    dispatch(
                                        setActivePreset({
                                            filterContextName,
                                            presetIndex: i,
                                        }),
                                    );
                                    updateFilters(p.filters);
                                    popupState.close();
                                }}
                            >
                                {p.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
        </PopupState>
    );
}
