import { Stack } from '@mui/system';
import * as React from 'react';
import { MultiVideoController } from '../../../lib/multi-video/multi-video';
import { DateTimestampMs } from '../../../types/stein';
import { AppIconPictureInPicture, AppIconSkipBack, AppIconSkipForward } from '../../AppIcons';
import { IconButtonSimple } from '../../material/IconButtonSimple';
import { VideoMultiControl } from '../../VideoPlayer/VideoControlLayouts';
import { VideoPlaybackRateSelect } from '../../VideoPlayer/VideoControls/PlaybackRateSelect';
import { useVideoMultiPlayButton } from '../../VideoPlayer/VideoControls/VideoPlayButton';
import { usePictureInPicture } from '../../VideoPlayer/videoPlayerHooks';
import { VideoRef } from '../../VideoPlayer/VideoTypes';

export const VehicleTimelineVideoControls = React.forwardRef(function VehicleTimelineVideoControls(
    {
        controller,
        videoRef,
    }: {
        controller: MultiVideoController;
        videoRef: VideoRef;
    },
    ref: React.ForwardedRef<HTMLDivElement>,
): React.ReactElement {
    const { onToggle, Icon } = useVideoMultiPlayButton(controller);
    const { enterPictureInPicture, exitPictureInPicture, isPictureInPicture } = usePictureInPicture(videoRef);

    return (
        <VideoMultiControl
            ref={ref}
            onClick={onToggle}
            bottom={
                <Stack spacing={1} direction={'row'}>
                    <IconButtonSimple
                        onClick={() =>
                            controller.playbackTime &&
                            controller.seekTo((controller.playbackTime - 10000) as DateTimestampMs)
                        }
                    >
                        <AppIconSkipBack />
                    </IconButtonSimple>

                    <IconButtonSimple sx={{ fontSize: 20 }} onClick={onToggle}>
                        <Icon />
                    </IconButtonSimple>

                    <IconButtonSimple
                        onClick={() =>
                            controller.playbackTime &&
                            controller.seekTo((controller.playbackTime + 10000) as DateTimestampMs)
                        }
                    >
                        <AppIconSkipForward />
                    </IconButtonSimple>
                </Stack>
            }
            bottomRight={
                <Stack spacing={1}>
                    <IconButtonSimple
                        sx={{ fontSize: 20 }}
                        onClick={
                            isPictureInPicture ? /* istanbul ignore next */ exitPictureInPicture : enterPictureInPicture
                        }
                    >
                        <AppIconPictureInPicture />
                    </IconButtonSimple>
                    <VideoPlaybackRateSelect videoRef={videoRef} components={{ Button: IconButtonSimple }} />
                </Stack>
            }
        ></VideoMultiControl>
    );
});
