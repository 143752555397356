import { SteinAPI as SteinInternalAPI } from '../../clients/stein-internal-api';
import EventEmitter from '@foxify/events';

import { FrameLogger } from '../../lib/frame-tokens';
import { AppStore } from '../../store';
import { VehicleDevice, VehicleDeviceSlug } from '../../types/stein';
import { RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';

import { connect } from 'twilio-video';
import { Device } from '@twilio/voice-sdk';

export type IntercomVideoCallAudioState = 'disconnected' | 'muted' | 'unmuted';

export type IntercomCall = Readonly<{
    toggleMute: () => void;
    disconnect: () => void;
    vehicleSlug: VehicleDeviceSlug;
    audioTracks: readonly AudioTrack[];
    videoTracks: readonly VideoTrack[];
    joinAudio(): void;
    audioState: IntercomVideoCallAudioState;
}>;

type IntercomEvents = {
    callChanged: (call: IntercomCall | null) => void;
    callPending: () => void;
};

export type IntercomEmitter = EventEmitter<IntercomEvents>;

export type IntercomService = {
    startVoiceCall: (vehicle: VehicleDevice) => Promise<void>;
    startVideoCall: (vehicle: VehicleDevice) => Promise<void>;
    emitter: IntercomEmitter;
};

export function createIntercomEmitter(): IntercomEmitter {
    return new EventEmitter<IntercomEvents>();
}

export type TwilioAudioDevice = typeof Device;
export type TwilioVideoConnect = typeof connect;

export type IntercomDeps = {
    store: AppStore;
    log: FrameLogger;
    emitter: IntercomEmitter;
    stein: SteinInternalAPI;
    AudioDevice: TwilioAudioDevice;
    connect: TwilioVideoConnect;
};

export type AudioTrack = RemoteAudioTrack;
export type VideoTrack = RemoteVideoTrack;
