// istanbul ignore file

import { Paper, Slide, Slider, Stack, Tooltip, Typography } from '@mui/material';
import {
    startOfDay,
    getUnixTime,
    fromUnixTime,
    formatDuration,
    intervalToDuration,
    addDays,
    areIntervalsOverlapping,
    min,
    max,
    isBefore,
} from 'date-fns';
import * as React from 'react';
import { SteinInternalApiClientToken } from '../../../clients/stein-internal-api';
import { useFrame } from '../../../lib/frame-react';
import { toISO8601, toTimestampMs } from '../../../utils/datetime-utils';
import {
    useMultiVideoBadge,
    useMultiVideoController,
    useMultiVideoRef,
    useMultiVideoState,
    VideoBadgeType,
} from '../../VideoPlayerMulti/VideoPlayerMulti';
import { VehicleDeviceSlug, VideoUploadRequest } from '../../../types/stein';
import { GetTimelineReq, GetVideoUploadRequestReq } from '../../../types/stein-internal-api';
import { ToParams } from '../../../types/util-types';
import { useParams } from 'react-router-dom';
import { VideoOverlayCentered, VideoPlayerBase } from '../../VideoPlayer/VideoPlayerBase';

import { useSearchParamNumber } from '../../../hooks/use-filters';
import { useVideoMultiHotkeys } from '../../VideoPlayerMulti/VideoMultiControls/useVideoMultiHotkeys';
import { useShowOnChange } from '../../../hooks/use-show-on-change';
import { VehicleTimelineScrubber } from './VehicleTimelineScrubber';

import { VehicleTimelineVideoControls } from './VehicleTimelineVideoControls';
import { Box } from '@mui/system';
import { appStyled, useAppTheme } from '../../../theme';
import { VehicleTimelineDatePicker } from './VehicleTimelineScrubber/VehicleTimelineDatePicker';
import { IconButtonSimple } from '../../material/IconButtonSimple';
import { AppIconChevronLeftDouble, AppIconChevronRightDouble, AppIconVehicle } from '../../AppIcons';
import { arrayToSingle } from '../../../utils/array-to-single';
import { VideoRefProvider } from '../../../hooks/use-video-ref';
import { useActiveProjectSlug } from '../../../hooks/use-active-project';
import { CreateVideoUploadRequest } from './CreateVideoUploadRequest';
import { Noise } from './Noise';
import { VehicleLocationHistory } from './VehicleLocationHistory';
import { urlVehicleDeviceList, urlVehicleDeviceShow } from '../../../utils/internal-url-utils';
import { Breadcrumbs } from '../../Breadcrumbs';
import { useAppSelector } from '../../../hooks/hooks';
import { selectVehicleBySlug } from '../../../store/selectors';
import { Permission } from '../../Authorize/PermissionBlock';

type Requests = {
    getTimelineParams: GetTimelineReq;
    getVideoUploadsParams: GetVideoUploadRequestReq;
};

type VehicleParams = { vehicleDeviceSlug: VehicleDeviceSlug };
function useVehicleParams(): VehicleParams {
    return useParams<ToParams<VehicleParams>>() as VehicleParams;
}

function useRequestParams(date: Date): Requests {
    const projectSlug = useActiveProjectSlug();
    const params = useVehicleParams();

    return React.useMemo(() => {
        const getTimelineParams: GetTimelineReq = {
            vehicleDeviceSlug: params.vehicleDeviceSlug as VehicleDeviceSlug,
            startTime: toISO8601(date),
        };

        const getVideoUploadsParams: GetVideoUploadRequestReq = {
            vehicleDeviceSlug: params.vehicleDeviceSlug as VehicleDeviceSlug,
            projectSlug,
        };

        return {
            getTimelineParams,
            getVideoUploadsParams,
        };
    }, [params.vehicleDeviceSlug, projectSlug, date]);
}

function VideoBadge({
    badge,
    videoRef,
}: {
    badge?: VideoBadgeType;
    videoRef: React.MutableRefObject<HTMLDivElement | null>;
}): React.ReactElement {
    const show = useShowOnChange(3000, badge);

    function renderBadge(badge?: VideoBadgeType): React.ReactElement {
        if (!badge) {
            return <></>;
        }
        switch (badge.type) {
            case 'finished':
                return <>{'Playback Finished'}</>;
            case 'skip':
                return <>{`Skipped ${formatDuration(intervalToDuration({ start: 0, end: badge.seconds * 1000 }))}`}</>;
        }
    }

    return (
        <Slide direction={'down'} in={show} container={videoRef.current}>
            <div style={{ marginLeft: 'auto' }}>
                <Paper sx={{ padding: 1, margin: 1, backgroundColor: 'rgba(255, 255, 255, 0.50)' }}>
                    {renderBadge(badge)}
                </Paper>
            </div>
        </Slide>
    );
}

const ZoomSlider = appStyled(Slider)({
    '&.MuiSlider-root': {
        padding: '0',
    },
});

export function VehicleTimelineShowPage(): React.ReactElement {
    const projectSlug = useActiveProjectSlug();
    const { vehicleDeviceSlug } = useVehicleParams();
    const vehicle = useAppSelector((s) => selectVehicleBySlug(s, vehicleDeviceSlug));
    const [startTimestamp, setStartTimestamp] = useSearchParamNumber('startTime');
    const [zoom, setZoom] = useSearchParamNumber('zoom');

    const date = React.useMemo<Date>(
        () => (startTimestamp ? fromUnixTime(startTimestamp) : startOfDay(new Date())),
        [startTimestamp],
    );

    const { useGetTimelineQuery, useGetVideoUploadRequestsQuery } = useFrame(SteinInternalApiClientToken);
    const req = useRequestParams(date);
    const { data, isFetching } = useGetTimelineQuery(req.getTimelineParams);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: vurRes, isFetching: isVideoUploadsFetching } = useGetVideoUploadRequestsQuery(
        req.getVideoUploadsParams,
    );

    const sortedDriverLogs = React.useMemo(() => {
        const log = (data?.driverLogs ? [...data?.driverLogs] : []).filter((dl) => (dl.duration || 0) >= 1);
        log.sort((a, b) => (isBefore(new Date(a.occurredAt || 0), new Date(b.occurredAt || 0)) ? -1 : 1));
        return log;
    }, [data?.driverLogs]);

    const [driverDriverLogs, roadDriverLogs] = React.useMemo(() => {
        const driver = sortedDriverLogs.filter((dl) => dl.cameraType === 0);
        const road = sortedDriverLogs.filter((dl) => dl.cameraType === 1);
        return [driver, road];
    }, [sortedDriverLogs]);

    const locationHistory = data?.locationHistory || [];

    const mergedVideoUploadRequests = React.useMemo(() => {
        const sources: VideoUploadRequest[] = [];
        let prev: VideoUploadRequest | null = null;
        const rawSources = vurRes?.videoUploadRequests || [];
        rawSources.forEach((vur, idx) => {
            if (!prev) {
                prev = { ...vur };
            } else if (
                areIntervalsOverlapping(
                    {
                        start: new Date(vur.startTime),
                        end: new Date(vur.endTime),
                    },
                    {
                        start: new Date(prev.startTime),
                        end: new Date(prev.endTime),
                    },
                )
            ) {
                prev.startTime = toISO8601(min([new Date(vur.startTime), new Date(prev.startTime)]));
                prev.endTime = toISO8601(max([new Date(vur.endTime), new Date(prev.endTime)]));
            } else {
                sources.push(prev);
                prev = { ...vur };
            }

            if (idx === rawSources.length - 1) {
                sources.push(prev);
            }
        });
        return sources;
    }, [vurRes]);

    const sources = React.useMemo(
        () =>
            driverDriverLogs
                ?.filter((s) => s.uploadStatus == 'ready')
                .map((dl) => ({
                    url: dl.url || '',
                    durationSeconds: dl.duration || 0,
                    startTime: toTimestampMs(new Date(dl.occurredAt || '')),
                    uploadStatus: dl.uploadStatus || '',
                })) || [],
        [driverDriverLogs],
    );

    const controller = useMultiVideoController(sources);
    const vidContainerRef = React.useRef<HTMLDivElement | null>(null);
    const videoRef = useMultiVideoRef(controller);
    const videoBadge = useMultiVideoBadge(controller);
    const theme = useAppTheme();
    const { currentSource, playbackState } = useMultiVideoState(controller);

    useVideoMultiHotkeys(controller);

    const handleStartDateChange = (date: Date | null): void => {
        date && setStartTimestamp(getUnixTime(date));
    };

    const overlays = [<VideoBadge badge={videoBadge} videoRef={vidContainerRef} key="badge" />];
    if (!isFetching && !sources.length) {
        overlays.push(
            <VideoOverlayCentered>
                <Typography>{'no videos on this day'}</Typography>
            </VideoOverlayCentered>,
        );
    } else if (!currentSource) {
        if (playbackState === 'playing') {
            overlays.push(<Noise />);
        }
        overlays.push(
            <VideoOverlayCentered>
                <Typography>{'no video at this time'}</Typography>
            </VideoOverlayCentered>,
        );
    }

    return (
        <VideoRefProvider videoRef={videoRef}>
            <Breadcrumbs
                items={[
                    {
                        Icon: AppIconVehicle,
                        name: 'Vehicles',
                        url: urlVehicleDeviceList(projectSlug),
                    },
                    {
                        name: vehicle?.name || '',
                        url: urlVehicleDeviceShow(projectSlug, vehicleDeviceSlug),
                    },
                    {
                        name: 'Timeline',
                    },
                ]}
            />
            <Box sx={{ width: '100%', maxHeight: 'calc(100vh - 280px)', aspectRatio: '16 / 9' }}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.grey[400],
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '100%',
                        width: '100%',
                        maxHeight: '100%',
                        maxWidth: '100%',
                    }}
                >
                    <VideoPlayerBase
                        controls={<VehicleTimelineVideoControls controller={controller} videoRef={videoRef} />}
                        hideControlsUnlessHover
                        videoRef={videoRef}
                        overlays={overlays}
                        containerRef={vidContainerRef}
                        loading={isFetching}
                    />
                </Box>
            </Box>
            <Stack
                direction={'row'}
                sx={{ height: '60px', backgroundColor: theme.palette.grey['800'], color: 'white' }}
            >
                <Stack>
                    <VehicleTimelineDatePicker date={date} onChange={handleStartDateChange} />
                    <Box padding={1} display={'flex'}>
                        <ZoomSlider
                            aria-label="Zoom"
                            min={1}
                            max={10}
                            value={zoom || 1}
                            step={0.1}
                            onChange={(_, v) => setZoom(arrayToSingle(v))}
                            size={'small'}
                        />
                    </Box>
                </Stack>
                <Tooltip title={'Previous 24 hours'}>
                    <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconButtonSimple
                            sx={{ padding: 0.5 }}
                            onClick={() => handleStartDateChange(addDays(date, -1))}
                        >
                            <AppIconChevronLeftDouble />
                        </IconButtonSimple>
                    </Box>
                </Tooltip>

                <VehicleTimelineScrubber
                    controller={controller}
                    startTime={toTimestampMs(date)}
                    audits={data?.audits || []}
                    driverEvents={data?.driverEvents || []}
                    driverLogs={driverDriverLogs}
                    roadDriverLogs={roadDriverLogs}
                    videoUploadRequests={mergedVideoUploadRequests}
                    locationHistory={locationHistory || []}
                    zoom={zoom || 1}
                />

                <Tooltip title={'Next 24 Hours'}>
                    <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconButtonSimple sx={{ padding: 0.5 }} onClick={() => handleStartDateChange(addDays(date, 1))}>
                            <AppIconChevronRightDouble />
                        </IconButtonSimple>
                    </Box>
                </Tooltip>
            </Stack>
            <Permission
                auth={{
                    subject: 'VehicleDevice',
                    action: 'manage_continuous_recording',
                }}
            >
                <CreateVideoUploadRequest date={date} vehicleDeviceSlug={req.getTimelineParams.vehicleDeviceSlug} />
            </Permission>
            <Permission debugOnly>
                <VehicleLocationHistory locations={locationHistory} controller={controller} />
            </Permission>
        </VideoRefProvider>
    );
}
