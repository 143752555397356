import * as React from 'react';
import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import { format, startOfDay } from 'date-fns';

import { DateTimePickerPopover } from '../../../DateTimePicker/DateTimePickerPopover';
import { IconButtonSimple } from '../../../material/IconButtonSimple';
import { useAppTheme } from '../../../../theme';
import { useDialogState } from '../../../../hooks/use-dialog-state';

type VehicleTimelineDatePickerProps = {
    date: Date;
    onChange: (d: Date) => void;
};

/* istanbul ignore next */
function startOfToday(): Date {
    return startOfDay(new Date());
}

export function VehicleTimelineDatePicker({ date, onChange }: VehicleTimelineDatePickerProps): React.ReactElement {
    const theme = useAppTheme();
    const dialog = useDialogState();

    return (
        <Box
            sx={{
                width: '80px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0.5,
            }}
        >
            <IconButtonSimple
                onClick={dialog.onOpen}
                sx={{
                    color: 'white',
                    backgroundColor: theme.palette.grey[700],
                    padding: 0.5,
                    flex: 1,
                    borderRadius: 1,
                }}
            >
                <Stack alignItems={'center'}>
                    <Box sx={{ fontSize: 11 }}>{format(date, 'MMM do')}</Box>
                    <Box sx={{ fontSize: 9 }}>{`${format(date, 'H:mm')} + 24h`}</Box>
                </Stack>
            </IconButtonSimple>
            <DateTimePickerPopover
                date={date}
                onDateChanged={onChange}
                open={dialog.isOpen}
                onClose={dialog.onClose}
                shortcutItems={[
                    {
                        label: 'Today',
                        getValue: startOfToday,
                    },
                ]}
            />
        </Box>
    );
}
