// istanbul ignore file
import * as React from 'react';
import { VideoPlayerBaseProps } from '../VideoPlayer/VideoPlayerBase';

import { VideoMultiSource } from './videoPlayerMultiTypes';
import { MultiVideoController, MultiVideoState } from '../../lib/multi-video/multi-video';

export type VideoPlayerMultiProps = {
    controller: MultiVideoController;
} & Partial<VideoPlayerBaseProps>;

export function useMultiVideoController(sources: VideoMultiSource[]): MultiVideoController {
    const controller = React.useMemo(() => new MultiVideoController(), []);
    React.useEffect(() => {
        console.log('controller.setSrcs');
        controller.setSrcs(sources);
    }, [controller, sources]);

    return controller;
}

export function useMultiVideoState(controller: MultiVideoController): MultiVideoState {
    // @ts-expect-error controller.state is private, but this is an acceptable use of it
    const [state, setState] = React.useState<MultiVideoState>(controller.state);
    React.useEffect(() => {
        controller.on('stateChanged', (s) => {
            setState(s);
        });
    }, [controller]);

    return state;
}

export function useMultiVideoRef(controller: MultiVideoController): React.RefObject<HTMLVideoElement> {
    const videoRef = React.useMemo(() => React.createRef<HTMLVideoElement>(), []);

    React.useEffect(() => {
        videoRef.current && controller.setVideoElement(videoRef.current);
    }, [controller, videoRef.current]);

    return videoRef;
}

export type VideoBadgeType =
    | {
          type: 'finished';
      }
    | {
          type: 'skip';
          seconds: number;
      };

export function useMultiVideoBadge(controller: MultiVideoController): VideoBadgeType | undefined {
    const [state, setState] = React.useState<VideoBadgeType>();
    React.useEffect(() => {
        function onPlaybackReset(): void {
            setState({ type: 'finished' });
        }
        function onPlaybackSkipped(n: number): void {
            setState({ type: 'skip', seconds: n });
        }
        controller.on('playbackReset', onPlaybackReset);
        controller.on('playbackSkipped', onPlaybackSkipped);
        return () => {
            controller.off('playbackReset', onPlaybackReset);
            controller.off('playbackSkipped', onPlaybackSkipped);
        };
    }, [controller, setState]);

    return state;
}
