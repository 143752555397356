import * as TwilioVideo from 'twilio-video';

import { VehicleDevice } from '../../../types/stein';
import { IntercomDeps, IntercomVideoCallAudioState } from '../intercom-types';

const LOCAL_PARTICIPANT_VIDEO_ENABLED = false;
const LOCAL_PARTICIPANT_AUDIO_ENABLED = false;

export function createStartIntercomFn(
    { emitter, log, store, stein, connect }: IntercomDeps,
    supportVideo: boolean,
): (v: VehicleDevice) => Promise<void> {
    function handleError(error: unknown): void {
        log.error('[IntercomService] error during video call', { error });
    }

    const videoTracks: TwilioVideo.RemoteVideoTrack[] = [];
    const audioTracks: TwilioVideo.RemoteAudioTrack[] = [];

    return async function startIntercomCall(vehicle: VehicleDevice): Promise<void> {
        emitter.emit('callPending');
        const callRes = await store
            .dispatch(
                stein.endpoints.startVideoCall.initiate(
                    { vehicleDeviceId: vehicle.id },
                    {
                        track: false,
                    },
                ),
            )
            .unwrap()
            .catch(handleError);

        if (!callRes) {
            return;
        }

        let audio: TwilioVideo.LocalAudioTrack | null = null;
        const room = await connect(callRes.callerAccessToken, {
            name: callRes.room,
            video: LOCAL_PARTICIPANT_VIDEO_ENABLED && /* istanbul ignore next */ supportVideo,
            audio: LOCAL_PARTICIPANT_AUDIO_ENABLED,
        });

        function getAudioState(): IntercomVideoCallAudioState {
            if (!room.localParticipant.audioTracks.size) {
                return 'disconnected';
            }
            const isMuted = !Array.from(room.localParticipant.audioTracks.values()).some((a) => a.isTrackEnabled);
            return isMuted ? 'muted' : 'unmuted';
        }

        // istanbul ignore next
        async function joinAudio(): Promise<void> {
            // istanbul ignore next
            audio = await TwilioVideo.createLocalAudioTrack({ name: 'local-audio' });
            // istanbul ignore next
            await room.localParticipant.publishTrack(audio);
            // istanbul ignore next
            handleAnyChange();
        }

        function handleAnyChange(): void {
            const audioState = getAudioState();
            emitter.emit('callChanged', {
                audioState,
                vehicleSlug: vehicle.slug,
                toggleMute: function toggleMute() {
                    if (audioState === 'muted') {
                        room.localParticipant.audioTracks.forEach((p) => p.track.enable());
                        handleAnyChange();
                    } else if (audioState === 'unmuted') {
                        room.localParticipant.audioTracks.forEach((p) => p.track.disable());
                        handleAnyChange();
                    }
                },

                joinAudio: joinAudio,
                disconnect: function disconnect() {
                    return room.disconnect();
                },
                audioTracks,
                videoTracks,
            });
        }

        function addTrack<T extends TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack>(track: T): void {
            // istanbul ignore else
            if (track.kind === 'audio') {
                audioTracks.push(track);
            } else if (supportVideo) {
                videoTracks.push(track);
            }
        }
        function removeTrack<T extends TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack>(track: T): void {
            // istanbul ignore else
            if (track.kind === 'audio') {
                const idx = audioTracks.indexOf(track);
                // istanbul ignore next
                if (idx >= 0) {
                    audioTracks.splice(idx, 1);
                }
            } else if (supportVideo) {
                const idx = videoTracks.indexOf(track);
                // istanbul ignore next
                if (idx >= 0) {
                    videoTracks.splice(idx, 1);
                }
            }
        }

        // istanbul ignore next
        function trackSubscribed(t: TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack): void {
            // istanbul ignore next
            addTrack(t);
            // istanbul ignore next
            handleAnyChange();
        }
        // istanbul ignore next
        function trackUnsubscribed(t: TwilioVideo.RemoteAudioTrack | TwilioVideo.RemoteVideoTrack): void {
            // istanbul ignore next
            removeTrack(t);
            // istanbul ignore next
            handleAnyChange();
        }

        function handleParticipantConnected(p: TwilioVideo.RemoteParticipant): void {
            getTracks(p.audioTracks).forEach(addTrack);
            getTracks(p.videoTracks).forEach(addTrack);
            handleAnyChange();

            p.on('trackSubscribed', trackSubscribed);
            p.on('trackUnsubscribed', trackUnsubscribed);
        }

        function handleParticipantDisconnected(p: TwilioVideo.RemoteParticipant): void {
            getTracks(p.audioTracks).forEach(removeTrack);
            getTracks(p.videoTracks).forEach(removeTrack);
            p.off('trackSubscribed', trackSubscribed);
            p.off('trackUnsubscribed', trackUnsubscribed);
            handleAnyChange();
            if (!room.participants.size) {
                room.disconnect();
            }
        }

        room.participants.forEach(handleParticipantConnected);
        room.on('participantConnected', handleParticipantConnected);
        room.on('participantDisconnected', handleParticipantDisconnected);
        room.on('disconnected', function roomDisconnected() {
            audio && /* istanbul ignore next */ audio.stop();
            emitter.emit('callChanged', null);
        });

        // istanbul ignore if
        if (!supportVideo) {
            await joinAudio();
        }

        handleAnyChange();
    };
}

type TrackPublication = TwilioVideo.RemoteAudioTrackPublication | TwilioVideo.RemoteVideoTrackPublication;

function getTracks<T extends TrackPublication>(trackMap: Map<string, T>): Exclude<T['track'], null>[] {
    const tracks: Exclude<T['track'], null>[] = [];

    trackMap.forEach((publication) => {
        // @ts-expect-error this should work
        publication.track && tracks.push(publication.track);
    });

    return tracks;
}
