// istanbul ignore file

import { range } from 'lodash';
import * as React from 'react';
import { useMeasure } from 'react-use';

const ASPECT_RATIO = 4 / 3;

function computeNumColumns(width: number, height: number, numItems: number): number {
    let optimalColumns = 1;
    let maxVideoWidth = 0;

    for (let cols = 1; cols <= numItems; cols++) {
        const videoWidth = width / cols;
        const videoHeight = videoWidth / ASPECT_RATIO;

        if (videoHeight * Math.ceil(numItems / cols) <= height) {
            if (videoWidth > maxVideoWidth) {
                maxVideoWidth = videoWidth;
                optimalColumns = cols;
            }
        }
    }

    return optimalColumns;
}

export function MultiBlock({ items }: { items: React.ReactNode[] }): React.ReactElement {
    const [ref, { width, height }] = useMeasure<HTMLDivElement>();
    const numCollumns = computeNumColumns(width, height, items.length);
    const numRows = Math.ceil(items.length / numCollumns);

    return (
        <div ref={ref} style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%' }}>
            {range(0, numRows).map((rowNum) => (
                <div key={rowNum} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {range(rowNum * numCollumns, rowNum * numCollumns + numCollumns).map((idx) => {
                        if (items.length > idx) {
                            return (
                                <div
                                    key={idx}
                                    style={{
                                        width: `${100 / numCollumns}%`,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            margin: 1,
                                            aspectRatio: ASPECT_RATIO,
                                            width: `100%`,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'black',
                                            borderRadius: '5px',
                                            color: 'white',
                                        }}
                                    >
                                        {items[idx]}
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            ))}
        </div>
    );
}
